import React, { forwardRef, PropsWithChildren, ReactElement } from 'react';
import Stack, { StackProps } from '@mui/material/Stack';
import Typography, { TypographyProps } from '@mui/material/Typography';

export type FormProps = StackProps<'form'>;

/**
 * Flexbox form designed for use with {@link Fieldset}
 *
 * @example
 * <Form onSubmit={onSubmit}>
 *   <FormTitle>Create Client</FormTitle>
 *   <Fieldset legend="Client Details">
 *     <FormControlContainer>
 *       <TextField {...props} />
 *     </FormControlContainer>
 *   </Fieldset>
 * </Form>
 */
export const Form = forwardRef<HTMLFormElement, FormProps>(
  ({ children, ...props }, ref) => (
    <Stack spacing={6} direction="column" component="form" {...props} ref={ref}>
      {children}
    </Stack>
  ),
);

export type FormTitleProps = Omit<TypographyProps, 'variant'>;

export const FormTitle = ({
  children,
  ...props
}: PropsWithChildren<FormTitleProps>): ReactElement => (
  <Typography variant="h1" {...props}>
    {children}
  </Typography>
);

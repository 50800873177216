import { forwardRef } from 'react';
import { SnackbarContent, SnackbarKey, SnackbarMessage } from 'notistack';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

const ProgressSnackbarContent = styled(SnackbarContent)(({ theme }) => ({
  backgroundColor: theme.palette.grey[800],
  padding: '6px 16px 6px 20px',
  boxShadow:
    '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
  borderRadius: '4px',
  letterSpacing: '0.01071em',
}));

const SnackbarText = styled('span')(({ theme }) => ({
  color: theme.palette.grey[50],
  fontSize: '0.875rem',
  padding: `8px 0`,
  lineHeight: 1.43,
}));

const ProgressSnackbar = forwardRef<
  HTMLDivElement,
  { message: SnackbarMessage }
>(({ message }, ref) => {
  return (
    <ProgressSnackbarContent ref={ref}>
      <Stack
        direction="row"
        alignItems="center"
        gap={2}
        sx={{ color: 'grey.50' }}
      >
        <CircularProgress size="20px" sx={{ color: 'grey.50' }} />
        <SnackbarText>{message}</SnackbarText>
      </Stack>
    </ProgressSnackbarContent>
  );
});

export default ProgressSnackbar;

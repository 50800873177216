import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { styled, SxProps, Theme } from '@mui/material/styles';
import { Fade, Skeleton, Typography, useTheme } from '@mui/material';
import { useUpdateEffect } from '@react-hookz/web';
import { faImageSlash } from '@fortawesome/pro-solid-svg-icons/faImageSlash';
import { FaIcon } from '../../DataDisplay/Icon/FaIcon';
import { ShuttlerockIcon } from '../../DataDisplay/Icon/ShuttlerockIcon';

type Size = 'small' | 'medium' | 'large';
const paddingForSize = (size: Size) =>
  ({
    small: '0.25rem',
    medium: '0.5rem',
    large: '1rem',
  })[size];

const borderRadiusForSize = (size: Size) =>
  ({
    small: '2px',
    medium: '4px',
    large: '8px',
  })[size];

const Container = styled('div')<{ size: Size; disabled: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  padding: ${({ size }) => paddingForSize(size)};
  min-width: 50px;
  background: ${(props) => `${props.theme.palette.grey[100]}`};
  border-radius: ${(props) => `${props.theme.shape.borderRadius}px`};
  cursor: ${(props) => (props.disabled ? 'initial' : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'initial')};
  user-select: none;
  position: relative;
  &:hover {
    background: ${(props) => `${props.theme.palette.grey[200]}`};
  }
`;

const Image = styled('img', {
  shouldForwardProp: (prop) => prop !== 'aspectRatio',
})<{
  aspectRatio?: string;
  size: Size;
}>`
  border-radius: ${({ size }) => borderRadiusForSize(size)};
  box-shadow: ${(props) => `${props.theme.shadows[2]}`};
  aspect-ratio: ${(props) => props.aspectRatio};
  background: repeating-conic-gradient(
      rgba(0, 0, 0, 0.2) 0% 25%,
      rgba(255, 255, 255, 0.35) 0% 50%
    )
    0%/24px 24px;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
`;

const DefaultImage = styled('div', {
  shouldForwardProp: (prop) => prop !== 'aspectRatio',
})<{
  aspectRatio?: string;
  size: Size;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: ${({ size }) => borderRadiusForSize(size)};
  box-shadow: ${(props) => `${props.theme.shadows[2]}`};
  aspect-ratio: ${(props) => props.aspectRatio || '9/16'};
  max-width: 100%;
  max-height: 100%;
  width: 100%;
`;

export type MediaTileProps = {
  /** The image source */
  image?: string;
  /** Optional aspect ratio to enforce, omit to use image aspect ratio */
  aspectRatio?: string;
  /** The alternate text for a11y */
  alt: string;
  /** Replaces the image for a placeholder text */
  placeholder?: ReactNode;
  /** Used for default logos and padding */
  size?: Size;
  /** Used to disable pointer and hover styles */
  disabled?: boolean;
  /** System stying */
  sx?: SxProps<Theme>;
  className?: string;
};

const isPrimitive = (type: string) => type === 'string' || type === 'number';

const makeTypographyVariant = (size: Size) => {
  switch (size) {
    case 'small':
    case 'medium':
      return 'inputLabel';
    case 'large':
    default:
      return 'subtitle1';
  }
};

const between = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);

const loaderAspect = (
  aspectRatio?: string,
): { width: number; height: number } => {
  const defaultDimensions = { width: between(40, 80), height: between(75, 90) };

  if (!aspectRatio) {
    return defaultDimensions;
  }

  try {
    const [width, height] = (aspectRatio || '')
      .split('/')
      .map((x) => +x.trim());

    if (Number.isNaN(width) || Number.isNaN(height)) {
      return defaultDimensions;
    }

    return width > height
      ? { width: 100, height: 100 * (height / width) }
      : { height: 100, width: 100 * (width / height) };
  } catch {
    return defaultDimensions;
  }
};

const Loading = ({
  aspectRatio,
  size,
}: {
  aspectRatio?: string;
  size: Size;
}) => {
  const dimensions = useRef(loaderAspect(aspectRatio));

  return (
    <Skeleton
      sx={{
        position: 'absolute',
        transform: 'none',
        borderRadius: borderRadiusForSize(size),
        width: `calc(${dimensions.current.width}% - calc(${paddingForSize(
          size,
        )} * 2))`,
        height: `calc(${dimensions.current.height}% - calc(${paddingForSize(
          size,
        )} * 2))`,
      }}
    />
  );
};

export const MediaTile = ({
  image,
  aspectRatio,
  alt,
  placeholder,
  size = 'large',
  sx,
  disabled = false,
  className,
}: MediaTileProps): ReactElement => {
  const theme = useTheme();
  const [visible, setVisible] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const loading = image && !visible && !loadError;
  const classes = [
    'MuiMediaTile-root',
    size && `MuiMediaTile-${size}`,
    className,
  ]
    .filter(Boolean)
    .join(' ');

  // Reset state if image changes
  useUpdateEffect(() => {
    setLoadError(false);
    setVisible(false);
  }, [image]);

  const onLoad = useCallback(() => setVisible(true), []);
  const onError = useCallback(() => setLoadError(true), []);

  const makeFaIconSize = (size: Size) => {
    switch (size) {
      case 'small':
        return 'medium';
      case 'medium':
        return 'large';
      case 'large':
        return 'xlarge';
      default:
        return 'large';
    }
  };

  const fallbackElement = useMemo(() => {
    const FaIconSize = makeFaIconSize(size);

    // Failed to load
    if (image && !placeholder && loadError) {
      return (
        <DefaultImage aspectRatio={aspectRatio} size={size}>
          <FaIcon
            icon={faImageSlash}
            fontSize={FaIconSize}
            sx={{
              color: theme.palette.grey[300],
            }}
          />
        </DefaultImage>
      );
    }

    // No image available
    if (!image && !placeholder) {
      return (
        <DefaultImage aspectRatio={aspectRatio} size={size}>
          <ShuttlerockIcon
            fontSize={FaIconSize}
            sx={{ color: theme.palette.grey[300] }}
          />
        </DefaultImage>
      );
    }

    // Placeholder provided instead of image
    if ((!image || loadError) && placeholder) {
      return isPrimitive(typeof placeholder) ? (
        <Typography
          variant={makeTypographyVariant(size)}
          component="span"
          color="grey.600"
          className="MuiMediaTile-placeholder"
        >
          {placeholder}
        </Typography>
      ) : (
        placeholder
      );
    }

    return null;
  }, [theme, loadError, image, aspectRatio, size, placeholder]);

  return (
    <Container className={classes} size={size} sx={sx} disabled={disabled}>
      {fallbackElement}
      {loading ? <Loading aspectRatio={aspectRatio} size={size} /> : null}
      {image ? (
        <Fade in={visible}>
          <Image
            src={image}
            aspectRatio={aspectRatio}
            alt={alt}
            size={size}
            onLoad={onLoad}
            onError={onError}
            sx={{ display: loadError ? 'none' : undefined }}
          />
        </Fade>
      ) : null}
    </Container>
  );
};

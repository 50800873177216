// All events should be sent in a format supported by Segment.
// See https://segment.com/docs/connections/spec/ecommerce/v2/

import { useEffect } from 'react';
import { isEmpty } from 'lodash-es';
import { useClientAccountContext } from '@shuttlerock/client-account-context';
import { useUser } from '@creative-foundation/auth';
import { useLocation } from 'react-router-dom';

export const useIdentify = (): void => {
  const { user } = useUser();
  const { defaultAccount } = useClientAccountContext();

  useEffect(() => {
    if (!window?.analytics) {
      return;
    }

    const traits = localStorage.getItem('ajs_user_traits');
    if (user && (isEmpty(traits) || traits === '{}')) {
      const client = defaultAccount;

      // TODO: Need a plan to transition Segment / Data Warehouse to the user's integrationKey instead.
      window.analytics.identify(user.legacyId, {
        id: user.legacyId,
        integrationKey: user.integrationKey,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        name: user.fullName,
        avatar: user.picture,
        clientId: client ? client.id : undefined,
        clientName: client ? client.name : undefined,
      });

      if (client) {
        window.analytics.group(`Client: ${client.name}`, {
          id: client.id,
          integrationKey: client.integrationKey,
          name: client.name,
          accountType: client.accountType,
          groupType: 'client',
        });
      }
    }

    window.analytics.page();
  }, [user, defaultAccount]);
};

function usePageViews() {
  const location = useLocation();

  useEffect(() => {
    window.analytics?.page();
  }, [location]);
}

export const Analytics = () => {
  useIdentify();
  usePageViews();

  return null;
};

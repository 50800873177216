import { useCallback, useMemo } from 'react';
import { Theme } from '@mui/material/styles';
import { useLocalStorageValue } from '@react-hookz/web';
import { merge } from 'lodash';
import { DarkTheme } from './darkTheme';
import { LightTheme } from './lightTheme';

export enum ThemeMode {
  Preset = 'preset',
  Light = 'light',
  Dark = 'dark',
}

export type UseGlobalTheme = {
  /** The theme to pass to the top level `ThemeProvider` */
  theme: Theme;
  /** The current theme mode */
  mode: ThemeMode;
  /** Set the theme mode */
  setTheme(mode: ThemeMode): void;
};

export type UseGlobalThemeProps = {
  /** Make all MUI animations complete instantly - useful for testing */
  disableAnimations?: boolean;
};

export const STORAGE_KEY = 'Shuttlerock:ThemeMode';

const dispatch = (curr: ThemeMode, next: ThemeMode) => {
  const storageArea = localStorage;
  const oldValue = JSON.stringify(curr);
  const newValue = JSON.stringify(next);
  const event = new StorageEvent('storage', {
    key: STORAGE_KEY,
    oldValue,
    newValue,
    storageArea,
  });
  window.dispatchEvent(event);
};

/**
 * `useGlobalTheme` returns which theme to pass to the top level `ThemeProvider`, the
 * current `ThemeMode` and functions to change the active theme.
 */
export const useGlobalTheme = ({
  disableAnimations,
}: UseGlobalThemeProps = {}): UseGlobalTheme => {
  const [mode, setMode] = useLocalStorageValue(STORAGE_KEY, ThemeMode.Preset);

  const setTheme = useCallback(
    (newMode: ThemeMode) => {
      const existingMode = mode;
      if (newMode !== existingMode) {
        setMode(newMode);
        dispatch(existingMode, newMode);
      }
    },
    [mode, setMode],
  );

  const theme = useMemo(() => {
    const activeTheme =
      mode === ThemeMode.Light || mode === ThemeMode.Preset
        ? LightTheme
        : DarkTheme;

    return disableAnimations
      ? merge({}, activeTheme, {
          transitions: {
            create: () => 'none',
            duration: Object.fromEntries(
              Object.entries(activeTheme.transitions.duration || {}).map(
                ([key]) => [key, 0],
              ),
            ),
          },
          components: {
            MuiCssBaseline: {
              styleOverrides: {
                '*, *::before, *::after': {
                  transition: 'none !important',
                  animation: 'none !important',
                },
              },
            },
          },
        })
      : activeTheme;
  }, [disableAnimations, mode]);

  return useMemo(() => ({ theme, mode, setTheme }), [theme, mode, setTheme]);
};

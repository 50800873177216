import { MarkGroupType } from './type';
import measureGroupWidth from './measureGroupWidth';

/**
 * Get the left position of expanded group
 * @param group
 * @param wrapperWidth
 */
const expandedLeftPosition = (
  group: MarkGroupType,
  wrapperWidth: number,
): number => {
  const collapsedCenterPoint = group.left + measureGroupWidth(group) / 2;
  const expandedWidth = measureGroupWidth(group, true);
  const expandedLeft = collapsedCenterPoint - expandedWidth / 2;

  // aligns expanded group to the left if its overflown on the left side
  if (expandedLeft < 0) {
    return 0;
  }

  // aligns expanded group to the right if it is overflown on the right side
  if (expandedLeft + expandedWidth > wrapperWidth) {
    return wrapperWidth - expandedWidth;
  }

  // if there is no overflown, aligns expanded group relative to the center point of collapsed group
  return expandedLeft;
};

export default expandedLeftPosition;

import { useLocation } from 'react-router-dom';
import { AuthClient } from '../internal/AuthClient';

export interface RedirectToLoginOptions {
  /**
   * The target page URL to redirect the user to after logging in.
   */
  target?: string;
}

export const useRedirectToLogin = () => {
  const location = useLocation();
  const currentPath = `${location.pathname}${location.search}`;

  return ({ target }: RedirectToLoginOptions = {}) => {
    return AuthClient.loginWithRedirect({
      appState: { target: target || currentPath },
    });
  };
};

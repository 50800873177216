/* eslint-disable @typescript-eslint/no-use-before-define,react/function-component-definition */
import {
  PropsWithChildren,
  ReactElement,
  ReactNode,
  ElementType,
  useCallback,
  useMemo,
} from 'react';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import {
  createColumnHelper,
  getCoreRowModel,
  ColumnDef,
  ExpandedState,
  Row,
  RowData,
  RowSelectionState,
  useReactTable,
  flexRender,
  OnChangeFn,
  ColumnSort,
  getSortedRowModel,
  getExpandedRowModel,
  CellContext,
} from '@tanstack/react-table';
import {
  SxProps,
  TableProps,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Theme,
  TableSortLabel,
  Stack,
  TableContainer,
  IconButton,
} from '@mui/material';
import { SkeletonRows } from './components/SkeletonRows';
import { FaIcon } from '../Icon/FaIcon';

export type SrTableProps<TData extends RowData> = {
  id?: string;
  columns: ColumnDef<TData>[];
  data: TData[];
  emptyState?: ReactNode;
  loading?: boolean;
  expansion?: TableExpandOptions;
  selection?: TableSelectionOptions<TData>;
  size?: TableProps['size'];
  sort?: TableSortOptions;
  sx?: SxProps<Theme>;
  onRowClick?(row: TData): void;
  /**
   * Determine the unique identifier for a row.
   *
   * Defaults to `row.id` (if it exists) otherwise falls back to the row index
   */
  getRowId?(row: TData, index: number): string;
  slots?: {
    TableHead?: ElementType;
  };
};

export type TableExpandOptions = {
  expanded: ExpandedState;
  setExpanded: OnChangeFn<ExpandedState>;
};

export type TableSortOptions = {
  sorting: SortingState[];
  setSorting: OnChangeFn<SortingState[]>;
  disableServerSorting?: boolean;
};

export type TableSelectionOptions<TData> = {
  rowSelection: RowSelectionState;
  setRowSelection: OnChangeFn<RowSelectionState>;
  multiple?: boolean;
  /** Filter function to determine if a row can be selected */
  filter?(row: RowSelectionFilterArgs<TData>): boolean;
};

export type RowSelectionFilterArgs<TData> = Row<TData>;

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SortingState = {
  field: string;
  order: SortOrder;
};

export type TableCellProps<
  TData extends RowData,
  TValue = unknown,
> = CellContext<TData, TValue>;

export type { ColumnDef, ExpandedState, RowSelectionState };
export { createColumnHelper };

export function SrTable<TData extends RowData>({
  id,
  columns,
  data,
  emptyState,
  loading,
  expansion,
  getRowId = defaultGetRowId<TData>,
  selection,
  size,
  sort,
  sx,
  onRowClick,
  slots,
}: SrTableProps<TData>): ReactElement {
  const [sorting, onSortingChange] = useSortState(sort);
  const { expanded, setExpanded: onExpandedChange } = expansion ?? {};
  const { rowSelection, setRowSelection: onRowSelectionChange } =
    selection ?? {};
  const { TableHead: TH = TableHead } = slots ?? {};

  const table = useReactTable({
    columns,
    data,
    manualSorting: !sort?.disableServerSorting,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: expansion ? getExpandedRowModel() : undefined,
    getSortedRowModel: sort ? getSortedRowModel() : undefined,
    enableRowSelection: selection?.filter ?? !!selection,
    enableMultiRowSelection: selection?.multiple,
    getSubRows,
    getRowId,
    onExpandedChange,
    onRowSelectionChange,
    onSortingChange,
    state: {
      rowSelection,
      expanded,
      sorting,
    },
  });

  const { rows } = table.getRowModel();

  return (
    <TableContainer sx={sx}>
      <Table id={id} size={size}>
        <TH>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const canSort = !!sort && header.column.getCanSort();
                const isSorted = header.column.getIsSorted();
                const direction = isSorted !== false ? isSorted : undefined;
                const content = flexRender(
                  header.column.columnDef.header,
                  header.getContext(),
                );

                return (
                  <TableCell
                    key={header.id}
                    className={header.id}
                    colSpan={header.colSpan}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {canSort ? (
                      <TableSortLabel active={!!isSorted} direction={direction}>
                        {content}
                      </TableSortLabel>
                    ) : (
                      content
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TH>
        <TableBody>
          {!loading && !rows.length && emptyState ? (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <Stack direction="row" justifyContent="center">
                  {emptyState}
                </Stack>
              </TableCell>
            </TableRow>
          ) : null}
          {rows.map((row) => {
            const canExpand = row.getCanExpand();
            const isExpanded = row.getIsExpanded();
            const isSelected = row.getCanSelect() && row.getIsSelected();
            const toggleExpanded = row.getToggleExpandedHandler();
            const onClick =
              onRowClick || (expansion && canExpand)
                ? () => {
                    if (expansion) toggleExpanded();
                    onRowClick?.(row.original);
                  }
                : undefined;

            return (
              <TableRow
                key={row.id}
                onClick={onClick}
                selected={isSelected}
                sx={[
                  !!onClick && {
                    '@media (hover: hover)': {
                      '&:hover': { backgroundColor: 'background.secondary' },
                    },
                  },
                  !!onClick && !isSelected && { cursor: 'pointer' },
                ]}
                className={`Sr-TableRowDepth-${row.depth}`}
              >
                {row.getVisibleCells().map((cell, i) => {
                  const content = flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext(),
                  );

                  return (
                    <TableCell key={cell.id} className={cell.column.id}>
                      {expansion && i === 0 ? (
                        <ExpandableWrapper
                          canExpand={canExpand}
                          depth={row.depth}
                          isExpanded={isExpanded}
                        >
                          {content}
                        </ExpandableWrapper>
                      ) : (
                        content
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
          <SkeletonRows loading={!!loading} columns={columns.length} />
        </TableBody>
      </Table>
    </TableContainer>
  );
}

SrTable.createBuilder = function createBuilder<TData extends RowData>() {
  return {
    ...createColumnHelper<TData>(),
    // Helper method to workaround https://github.com/TanStack/table/issues/4302
    // (`createColumnHelper` output types cannot be inferred correctly)
    columns(...columns: ColumnDef<TData, never>[]) {
      return columns as ColumnDef<TData>[];
    },
  };
};

type ExpandableWrapperProps = {
  canExpand: boolean;
  depth: number;
  isExpanded: boolean;
};

function ExpandableWrapper({
  canExpand,
  depth,
  isExpanded,
  children,
}: PropsWithChildren<ExpandableWrapperProps>) {
  return (
    <Stack
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        pl: (theme) =>
          `calc(var(--depth) * ${theme.spacing(3)} + var(--offset))`,
      }}
      style={
        { '--depth': depth, '--offset': !canExpand ? '30px' : '0px' } as never
      }
    >
      {canExpand ? (
        <IconButton
          className="expander"
          aria-label="expand row"
          component="div"
          sx={{ mr: 1, fontSize: 'caption.fontSize' }}
        >
          <FaIcon
            icon={faChevronRight}
            className={isExpanded ? 'expanded' : ''}
            sx={{
              '&.expanded': { transform: 'rotate(90deg)' },
              transition: (theme) =>
                theme.transitions.create('transform', { duration: 200 }),
            }}
          />
        </IconButton>
      ) : null}
      {children}
    </Stack>
  );
}

function getSubRows<TData>(row: TData): TData[] | undefined {
  return typeof row === 'object' && !!row && 'subRows' in row
    ? (row.subRows as TData[])
    : undefined;
}

function mapSortingState(state: SortingState): ColumnSort {
  return {
    id: state.field,
    desc: state.order === SortOrder.Desc,
  };
}

function mapColumnSort(sort: ColumnSort): SortingState {
  return {
    field: sort.id,
    order: sort.desc ? SortOrder.Desc : SortOrder.Asc,
  };
}

function useSortState(
  sort: TableSortOptions | undefined,
): [ColumnSort[], OnChangeFn<ColumnSort[]>] {
  const sorting = useMemo(
    () => sort?.sorting?.map(mapSortingState) ?? [],
    [sort?.sorting],
  );

  const onSortingChange: OnChangeFn<ColumnSort[]> = useCallback(
    (newSorting) => {
      const setValue = sort?.setSorting;
      if (!setValue) return;

      if (typeof newSorting === 'function') {
        setValue((prev) =>
          newSorting(prev.map(mapSortingState)).map(mapColumnSort),
        );
        return;
      }

      setValue(newSorting.map(mapColumnSort));
    },
    [sort?.setSorting],
  );

  return [sorting, onSortingChange];
}

function defaultGetRowId<TData extends RowData>(
  row: TData,
  index: number,
): string {
  return typeof row === 'object' &&
    !!row &&
    'id' in row &&
    typeof row.id === 'string'
    ? row.id
    : index.toString();
}

const MARK_SIZE = 10;

const COLLAPSED_MARK_SIZE = 3;
const COLLAPSED_MARK_GAP = 1.5;
const EXPANDED_MARK_GAP = 2.5;
const GROUP_PADDING = 4;
const MAX_MARK_PER_GROUP = 3;

export {
  MARK_SIZE,
  COLLAPSED_MARK_SIZE,
  COLLAPSED_MARK_GAP,
  EXPANDED_MARK_GAP,
  GROUP_PADDING,
  MAX_MARK_PER_GROUP,
};

import React, {
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from 'react';
import {
  IconButton,
  InputAdornment,
  Select,
  SelectProps,
  SelectChangeEvent,
  Typography,
  MenuItemProps,
} from '@mui/material';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import MenuItem from '@mui/material/MenuItem';
import { FaIcon } from '../../DataDisplay/Icon/FaIcon';

export type SrSelectProps = Omit<
  SelectProps,
  'name' | 'label' | 'placeholder' | 'error' | 'onChange'
> & {
  name: string;
  placeholder?: string | ReactNode | undefined;
  selectedItemTemplate?: ReactNode;
  onChange: (v: string) => void;
  readOnly?: boolean;
  clearButton?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
};

export interface SrSelectComponent extends FC<SrSelectProps> {
  Item: FC<MenuItemProps>;
}

/*
 * Select Item styles
 * for the inner MenuItem layout
 * to support a multi-row version with icons.
 */
const styles = {
  selectItem: {
    selected: {
      width: '100%',
      '& > div': { pt: '10px', pb: '10px' }, // Override MUI input size
      '& > div > div > div': { width: '100%' },
    },
    dropdown: {
      '& svg': { mr: '14px' }, // Icon, if added
      '& > p, & > p > div': { width: '100%' },
    },
  },
};

export const SrSelect: SrSelectComponent = ({
  id: inId,
  children,
  multiline,
  value,
  name,
  placeholder,
  selectedItemTemplate,
  readOnly,
  disabled,
  onFocus,
  onBlur,
  onChange,
  inputProps,
  sx,
  clearButton,
  startAdornment,
  endAdornment,
}: SrSelectProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLElement | null>();

  const id = inId || name;

  const ArrowDropdownIcon = useCallback(
    () => (
      <FaIcon
        className="icon-dropdown-arrow"
        icon={faChevronDown}
        sx={{
          pointerEvents: 'none',
          userSelect: 'none',
          position: 'absolute',
          right: 15,
          transform: isOpen ? 'rotate(180deg)' : 'rotate(0)',
          color: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey.A500
              : theme.palette.grey.A700,
        }}
      />
    ),
    [isOpen],
  );

  const handleChange = (e: SelectChangeEvent<unknown>) => {
    if (onChange) {
      onChange(e.target.value as string);
    }
  };

  const handleReset = () => {
    onChange('');
  };

  const getRenderValue = (): (() => ReactNode) | undefined => {
    if (value) {
      return selectedItemTemplate ? () => selectedItemTemplate : undefined;
    }
    return () => placeholder;
  };

  return (
    <div style={{ width: '100%' }}>
      <Select
        ref={selectRef}
        multiline={multiline}
        open={isOpen}
        IconComponent={ArrowDropdownIcon}
        inputProps={{
          'data-testid': `${id}-input`,
          ...inputProps,
        }}
        id={id}
        name={name}
        value={value}
        // @ts-expect-error Allow non-string placeholder (ReactNode)
        placeholder={placeholder}
        displayEmpty={!!placeholder}
        renderValue={getRenderValue()}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        onChange={handleChange}
        readOnly={readOnly}
        startAdornment={
          startAdornment ? (
            <InputAdornment position="start" sx={{ mr: 1.5 }}>
              {startAdornment}
            </InputAdornment>
          ) : null
        }
        endAdornment={
          endAdornment || clearButton ? (
            <InputAdornment position="end" sx={{ mr: 3.5 }}>
              {endAdornment}
              {clearButton && value ? (
                <IconButton onClick={handleReset}>
                  <FaIcon icon={faXmark} />
                </IconButton>
              ) : null}
            </InputAdornment>
          ) : null
        }
        sx={[styles.selectItem.selected, ...(Array.isArray(sx) ? sx : [sx])]}
      >
        {children}
      </Select>
    </div>
  );
};

const SrSelectItem = (props: MenuItemProps): React.JSX.Element => {
  return (
    <MenuItem {...props} sx={styles.selectItem.dropdown}>
      <Typography noWrap>{props.children}</Typography>
    </MenuItem>
  );
};

SrSelect.Item = SrSelectItem;

import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
} from 'react';
import { ClientAccountContext } from './types';
import { singleton } from './sharescope';

const Context = singleton(
  'client-account-context/Context',
  createContext<ClientAccountContext>({
    isMultiBrandUser: false,
    isMultiAccountUser: false,
  }),
);

export type ClientAccountContextProps = {
  value: ClientAccountContext;
  children: ReactNode;
};

type ClientAccountProviderComponent = (
  props: ClientAccountContextProps,
) => ReactElement;

export const ClientAccountProvider: ClientAccountProviderComponent = singleton(
  'client-account-context/ClientAccountProvider',
  ({ value, children }: ClientAccountContextProps) => (
    <Context.Provider value={value}>{children}</Context.Provider>
  ),
);

export const useClientAccountContext: () => ClientAccountContext = singleton(
  'client-account-context/useClientAccountContext',
  () => useContext(Context),
);

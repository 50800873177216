import { useRedirectToLogin, useUser } from '@creative-foundation/auth';
import { ErrorPage } from './ErrorPage';
import { useTranslation } from '../../i18n';

export const AccessDeniedPage = () => {
  const { t } = useTranslation('errorPage');
  const { user } = useUser();
  const redirectToLogin = useRedirectToLogin();
  const isLoggedOut = !user?.auth0IntegrationKey;

  return (
    <ErrorPage
      title={t('denied:title')}
      body={t(isLoggedOut ? 'denied:loggedOut' : 'denied:loggedIn')}
      primaryButton={
        isLoggedOut
          ? {
              text: t('denied:button:primary'),
              onClick: () => redirectToLogin(),
            }
          : undefined
      }
    />
  );
};

import {
  COLLAPSED_MARK_GAP,
  COLLAPSED_MARK_SIZE,
  MARK_SIZE,
  GROUP_PADDING,
  MAX_MARK_PER_GROUP,
  EXPANDED_MARK_GAP,
} from './constants';
import { MarkGroupType } from './type';

/**
 * measure the width of a group of marks
 * @param group - the group of marks
 * @param expanded = false - option to measure the width when the group is expanded
 */
const measureGroupWidth = (group: MarkGroupType, expanded = false): number => {
  const numElements = group.marks.length;

  if (numElements === 0) {
    return 0;
  }

  if (numElements === 1) {
    return MARK_SIZE;
  }

  if (expanded) {
    return (
      GROUP_PADDING * 2 +
      MARK_SIZE * numElements +
      EXPANDED_MARK_GAP * (numElements - 1)
    );
  }
  const markCount = Math.min(numElements, MAX_MARK_PER_GROUP);
  return (
    GROUP_PADDING * 2 +
    COLLAPSED_MARK_SIZE * markCount +
    COLLAPSED_MARK_GAP * (markCount - 1)
  );
};

export default measureGroupWidth;

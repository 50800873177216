import { Routes } from '@shuttlerock/router';
import { ErrorPage } from './ErrorPage';
import { useTranslation } from '../../i18n';

export const GonePage = ({ message }: { message: string }) => {
  const { t } = useTranslation('errorPage');
  return (
    <ErrorPage
      title={t('gone:title')}
      body={message || t('gone:defaultMessage')}
      primaryButton={{
        text: t('gone:button:primary'),
        route: Routes.External.ContactSupport,
      }}
      secondaryButton={{
        text: t('gone:button:secondary'),
        route: Routes.Home,
      }}
    />
  );
};

import React, {
  createContext,
  ReactElement,
  ReactNode,
  useMemo,
  useState,
} from 'react';
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons/faCircleQuestion';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons/faTriangleExclamation';
import {
  FormControl as FormControlBase,
  FormControlProps as FormControlBaseProps,
  FormControlLabel as FormControlLabelBase,
  FormControlLabelProps as FormControlLabelBaseProps,
  FormHelperText as FormHelperTextBase,
  FormHelperTextProps as FormHelperTextBaseProps,
  FormLabel as FormLabelBase,
  FormLabelProps as FormLabelBaseProps,
  useFormControl as useFormControlBase,
  FormControlState as FormControlBaseState,
  Tooltip,
  Theme,
  SxProps,
  Stack,
  Collapse,
  FormHelperTextTypeMap,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { FaIcon } from '../../DataDisplay/Icon/FaIcon';

type FormControlContext = { id?: string };
const Context = createContext<FormControlContext | undefined>(undefined);

export type FormControlState = FormControlBaseState & FormControlContext;

export function useFormControl(): FormControlState | undefined {
  const baseState = useFormControlBase();
  const state = React.useContext(Context);

  return useMemo(
    () => (baseState && state ? { ...baseState, ...state } : undefined),
    [baseState, state],
  );
}

export type FormControlProps = Omit<FormControlBaseProps, 'id'> & {
  id: string;
  containerId?: string;
  'data-testid'?: string;
};

export const FormControl = ({
  id,
  containerId: containerIdProp,
  'data-testid': dataTestId,
  children,
  ...props
}: FormControlProps): ReactElement => {
  const contextValue = useMemo(() => ({ id }), [id]);
  const containerId = containerIdProp ?? `${id}-container`;
  const testId = dataTestId ?? containerId;

  return (
    <Context.Provider value={contextValue}>
      <FormControlBase {...props} id={containerId} data-testid={testId}>
        {children}
      </FormControlBase>
    </Context.Provider>
  );
};

export type HelperTooltipProps = {
  tooltip: NonNullable<ReactNode>;
};

export const HelperTooltip = ({
  tooltip,
}: HelperTooltipProps): ReactElement => {
  // Controlled for better touch device UX
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <Tooltip
      title={tooltip}
      open={showTooltip}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
      disableTouchListener
      role="tooltip"
    >
      <FaIcon
        className="MuiHelperTooltip-icon"
        icon={faCircleQuestion}
        sx={{
          fill: (theme: Theme) => theme.palette.grey[400],
          marginBottom: 0.5,
          verticalAlign: 'middle',
        }}
        onClick={() => setShowTooltip(!showTooltip)}
      />
    </Tooltip>
  );
};

export type FormLabelProps = FormLabelBaseProps;

const formLabelStyle: SxProps<Theme> = {
  color: 'text.primary',
  '&.Mui-focused:not(.Mui-error)': {
    color: 'text.primary',
  },
  '.MuiHelperTooltip-icon': {
    ml: 1,
  },
};

export const FormLabel = ({
  id: idProp,
  htmlFor: htmlForProp,
  required: requiredProp,
  sx,
  children,
  ...props
}: FormLabelProps): ReactElement => {
  const formControl = useFormControl();
  const id = idProp ?? ((formControl?.id && `${formControl.id}-label`) || '');
  const htmlFor = htmlForProp ?? formControl?.id;
  const required = requiredProp ?? formControl?.required;

  const content = (
    <FormLabelBase
      {...props}
      id={id}
      htmlFor={htmlFor}
      required={required}
      sx={[formLabelStyle, ...(Array.isArray(sx) ? sx : [sx])]}
    >
      {children}
    </FormLabelBase>
  );

  return required ? (
    <Stack
      className="MuiFormLabel-requiredContainer"
      direction="row"
      gap={1}
      justifyContent="space-between"
    >
      {content}
      <FormHelperTextBase
        className="MuiFormLabel-requiredIndicator"
        component="span"
        sx={{ lineHeight: 1.4375, m: 0 }}
      >
        {/* FIXME: i18n */}
        Required
      </FormHelperTextBase>
    </Stack>
  ) : (
    content
  );
};

export type FormHelperTextProps = Omit<FormHelperTextBaseProps, 'error'>;
export const FormHelperText: OverridableComponent<FormHelperTextTypeMap> = (
  props: FormHelperTextProps,
): ReactElement => {
  return <FormHelperTextBase {...props} error={false} />;
};

export type FormControlLabelProps = FormControlLabelBaseProps & {
  size?: FormControlState['size'];
};
export const FormControlLabel = ({
  size: sizeProp,
  className,
  ...props
}: FormControlLabelProps): ReactElement => {
  const formControl = useFormControl();
  const size = sizeProp ?? formControl?.size;
  const classes = [className, size === 'medium' && 'MuiFormControlLabel-medium']
    .filter(Boolean)
    .join(' ');

  return <FormControlLabelBase className={classes} {...props} />;
};

export type FormErrorTextProps = FormHelperTextProps;
export const FormErrorText: OverridableComponent<FormHelperTextTypeMap> = ({
  role = 'alert',
  sx,
  children,
}: FormErrorTextProps): ReactElement => {
  const formControl = useFormControl();
  const isVisible = !formControl || formControl.error;

  return (
    <Collapse in={isVisible}>
      <FormHelperTextBase
        error
        component="div"
        role={role}
        sx={[
          { display: 'inline-flex', alignItems: 'center', gap: 0.5 },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <FaIcon icon={faTriangleExclamation} /> {children}
      </FormHelperTextBase>
    </Collapse>
  );
};

import { Box, Slider, Theme } from '@mui/material';
import { SxProps } from '@mui/material/styles';
import React, { useMemo } from 'react';
import { useMeasure } from '@react-hookz/web';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { MARK_SIZE } from './constants';
import { MarkType } from './type';
import arrangeMarks from './arrangeMarks';
import Mark from './Mark';
import StackedMark from './StackedMark';

type Props = {
  duration: number;
  framesPerSecond: number;
  marks?: MarkType[];
  marksDisabled?: boolean;
  onClickMark?: (m: MarkType) => void;
  onSeek: (n: number) => void;
  progress: number;
  seekDisabled?: boolean;
  sx?: SxProps;
};

dayjs.extend(duration);

export const formatVideoTime = (second: number): string => {
  return dayjs.duration(second, 'seconds').format('m:ss');
};

const formatCurrentFrame = (
  elapsedSeconds: number,
  framesPerSecond: number,
): string => {
  const frames = Math.round(elapsedSeconds * framesPerSecond);

  const time = formatVideoTime(frames / framesPerSecond);
  const remainingFrames = frames % framesPerSecond;

  return time.concat(':', String(remainingFrames).padStart(2, '0'));
};

const Seeker: React.FC<Props> = ({
  duration,
  framesPerSecond,
  marks = [],
  marksDisabled,
  onClickMark,
  onSeek,
  progress,
  seekDisabled,
  sx,
}) => {
  const [measurements, sliderRef] = useMeasure<HTMLSpanElement>();

  const boxSx = { ...sx };

  const handleChange = (_: unknown, value: number | number[]) => {
    onSeek(value as number);
  };

  const handleClick = (mark: MarkType) => () => {
    if (!marksDisabled) onClickMark?.(mark);
  };

  // Marks to be displayed in MUI Slider component (only visible on mobile)
  const muiSliderMarks = React.useMemo(() => {
    if (marks) {
      const unique = new Set<number>();
      marks.forEach((m) => unique.add(m.timestamp));
      return Array.from(unique, (t) => ({ value: t }));
    }
    return [];
  }, [marks]);

  const groupedMarks = useMemo(
    () => arrangeMarks(marks || [], duration, measurements?.width || 0),
    [marks, duration, measurements],
  );

  return (
    <Box sx={{ position: 'relative', mb: { md: 2 }, ...boxSx }}>
      <Slider
        aria-label="seeker"
        disabled={seekDisabled}
        onChange={handleChange}
        ref={sliderRef}
        sx={{
          py: 0.5,
          ':hover .MuiSlider-thumb': {
            width: '20px',
            height: '20px',
          },
        }}
        value={progress}
        min={0}
        max={duration}
        step={1 / framesPerSecond}
        marks={muiSliderMarks}
        slotProps={{
          mark: {
            sx: {
              display: { sm: 'none' },
              backgroundColor: '#fff',
              width: '3px',
              height: '3px',
            },
          },
          thumb: {
            sx: {
              '@media (hover: hover)': {
                width: '0px',
                height: '0px',
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                transition: (theme: Theme) =>
                  theme.transitions.create(['width', 'height'], {
                    duration: theme.transitions.duration.shorter,
                  }),
              },
            },
          },
        }}
        valueLabelDisplay="auto"
        valueLabelFormat={(p: number) => formatCurrentFrame(p, framesPerSecond)}
      />
      <Box
        sx={{
          width: 1,
          display: { xs: 'none', sm: 'flex' },
          alignItems: 'center',
          height: MARK_SIZE,
        }}
      >
        {groupedMarks.map((group) => {
          return group.marks.length > 1 ? (
            <StackedMark
              key={group.marks[0].id}
              group={group}
              onClickMark={onClickMark}
              wrapperWidth={measurements?.width || 0}
            />
          ) : (
            <Mark
              key={group.marks[0].id}
              data-testid={`mark-${group.marks[0].id}`}
              aria-label="mark"
              active={group.marks[0].active}
              onClick={handleClick(group.marks[0])}
              style={{
                left: group.left,
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default Seeker;

/* eslint-disable @typescript-eslint/no-throw-literal */
import { json, redirect } from 'react-router-dom';
import { Routes } from '@shuttlerock/router';
import { User, UserRole } from '@shuttlerock/auth-types';
import { AuthClient, GetTokenOptions } from './internal/AuthClient';
import { FetchUserError, TokenError } from './internal/errors';

export type GetAccessTokenSilentlyResult =
  | { accessToken: string; error?: never }
  | { accessToken?: never; error: Error };

/**
 * Provides a safe way to get the users access token without throwing or triggering an OAuth redirect.
 *
 * An error will be returned in the result object if the SDK fails to retrieve the token, so the failure can be handled by the caller.
 */
export async function getAccessTokenSilently(
  options?: GetTokenOptions,
): Promise<GetAccessTokenSilentlyResult> {
  try {
    return { accessToken: await AuthClient.getAccessTokenSilently(options) };
  } catch (error) {
    return { error: TokenError(error) };
  }
}

/**
 * A loader utility to get the current user and their access token.
 *
 * @example
 * import { Route } from 'react-router-dom';
 * import { getUser } from '@shuttlerock/creative-foundation-proxy';
 *
 * export const SomePublicRoute = <Route
 *   path="/some-public-route"
 *   element={<SomePublicPage />}
 *   loader={async ({ request }) => {
 *     const userData = await getUserData(request);
 *
 *     if (userData?.user) {
 *       // do something with the user
 *     }
 *   })
 * />
 */
export async function getUserData(_: Request) {
  // Ensure cached user details are up-to-date / not expired
  const { accessToken, error } = await getAccessTokenSilently();
  if (error instanceof FetchUserError) throw json(error.message, 500);
  if (error) return undefined;

  const user = AuthClient.getUser();
  return user ? { accessToken, user } : undefined;
}

/** Checks if the provided user has the specified role(s) */
export function hasRole(
  user: User | undefined,
  role: UserRole | readonly UserRole[],
) {
  const roles = Array.isArray(role) ? role : [role];
  return !!user?.roles.find((claim: UserRole) => roles.includes(claim));
}

/**
 * A loader utility that ensures the user is authenticated with our IDP.
 *
 * # This function has limited use-cases. You probably want {@link requireAuth} instead.
 *
 * This should be the first function called in the loader and be outside any `try/catch` blocks, so
 * it can short-circuit the loader and require the user to login if there is no current session.
 */
export async function requireSession(request: Request) {
  const userData = await getUserData(request);

  if (!userData) {
    const url = new URL(request.url);
    const target = `${url.pathname}${url.search}`;
    await AuthClient.loginWithRedirect({ appState: { target } });
    throw json('login_required', 401);
  }

  return userData;
}

/**
 * A loader utility that ensures the user is authenticated, has a user record in our database, and (optionally) has the specified role(s)
 *
 * This should be the first function called in the loader and be outside any `try/catch` blocks, so
 * it can short-circuit the loader and return an error response when the user is not authenticated/authorised.
 *
 * @example
 * import { Route } from 'react-router-dom';
 * import { requireAuth, UserRole } from '@shuttlerock/creative-foundation-proxy';
 * import { AdminPage } from './AdminPage';
 *
 * export const AdminRoute = <Route
 *   path="/admin"
 *   element={<AdminPage />}
 *   loader={async ({ request }) => {
 *     await requireAuth(request, UserRole.ADMIN);
 *
 *     try {
 *       // other loader logic (load page data etc.)
 *     } catch (error) {
 *       // handle errors
 *     }
 *   }}
 * />
 */
export async function requireAuth(
  request: Request,
  role?: UserRole | readonly UserRole[],
) {
  const userData = await requireSession(request);

  if (!userData.user.integrationKey) {
    throw redirect(Routes.Auth.SIGN_UP);
  }

  const isAuthorized = !role || hasRole(userData.user, role);
  if (!isAuthorized) {
    throw json('access_denied', 403);
  }

  return userData;
}

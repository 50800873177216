import React, { forwardRef, ReactElement } from 'react';
import {
  FilledInput,
  FilledInputProps,
  OutlinedInput,
  OutlinedInputProps,
  useTheme,
} from '@mui/material';
import { useFormControl } from '../FormControl/FormControl';

export type TextInputProps =
  | ({ variant: 'outlined' } & OutlinedInputProps)
  | ({ variant: 'filled' } & FilledInputProps)
  | ({ variant?: never } & OutlinedInputProps);

/**
 * A stand-alone `text` input (or `textarea`).
 *
 * No `helperText`, `cornerHelperText`, `leftCornerElement`, `bottomRightButSlightlyToTheLeftAndUpABit` props.
 * If you want all that fun stuff, use {@link TextField} instead (or don't).
 */
// Match MUI typing for `ref` prop (`any`)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TextInput = forwardRef<any, TextInputProps>(
  (
    { id: idProp, size: sizeProp, variant: variantProp, ...props },
    ref,
  ): ReactElement => {
    const theme = useTheme();
    const formControl = useFormControl();
    const id = idProp || formControl?.id;
    const textDefaults = theme.components?.MuiTextField?.defaultProps;
    const controlDefaults = theme.components?.MuiFormControl?.defaultProps;
    const size =
      sizeProp ||
      formControl?.size ||
      textDefaults?.size ||
      controlDefaults?.size;
    const variant =
      variantProp ||
      (formControl?.variant as 'outlined' | 'filled') ||
      textDefaults?.variant ||
      controlDefaults?.variant;
    const Component = variant === 'filled' ? FilledInput : OutlinedInput;

    return <Component {...props} id={id} size={size} ref={ref} />;
  },
);

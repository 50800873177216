import { useCallback, useEffect, useState } from 'react';

type UseFullscreenControlReturnType = {
  showControl: boolean;
  toggleControl: (nextState?: boolean) => void;
  autoHideControl: (timeout?: number) => void;
};

type UseFullScreenControlProps = {
  initialState?: boolean;
  onChange?: (toggle: boolean) => void;
};

const FULLSCREEN_CONTROL_TIMEOUT = 3000;

const useFullscreenControlState = ({
  initialState = true,
  onChange,
}: UseFullScreenControlProps = {}): UseFullscreenControlReturnType => {
  const [showControl, setShowControl] = useState(initialState);
  const [controlTimeout, setControlTimeout] = useState<number | undefined>();

  const clearAutoHideTimeout = useCallback(() => {
    if (controlTimeout) {
      clearTimeout(controlTimeout);
    }
  }, [controlTimeout]);

  const autoHideControl = (timeout = FULLSCREEN_CONTROL_TIMEOUT) => {
    clearAutoHideTimeout();
    setControlTimeout(
      window.setTimeout(() => {
        setShowControl(false);
      }, timeout),
    );
  };

  const toggleControl = (nextState?: boolean) => {
    // if nextState argument is exist, we override default behavior
    if (nextState !== undefined) {
      setShowControl(nextState);
      onChange?.(nextState);
      return;
    }

    if (showControl) {
      setShowControl(false);
      onChange?.(false);
    } else {
      setShowControl(true);
      onChange?.(true);
      autoHideControl();
    }
  };

  useEffect(() => {
    return () => {
      clearAutoHideTimeout();
    };
  }, [clearAutoHideTimeout]);

  return {
    showControl,
    toggleControl,
    autoHideControl,
  };
};

export default useFullscreenControlState;

import { MarkType, MarkGroupType } from './type';
import measureGroupWidth from './measureGroupWidth';

const isOverflown = (group: MarkGroupType, wrapperWidth: number): boolean => {
  return group.left + measureGroupWidth(group) > wrapperWidth;
};

const isOverlap = (prevGroup: MarkGroupType, currentLeft: number): boolean => {
  return prevGroup.left + measureGroupWidth(prevGroup) > currentLeft;
};

/**
 * Arrange each mark into position relative to the wrapper, and group them when overlapping each other
 * @param marks - an array of mark
 * @param duration - the duration of the video
 * @param wrapperWidth - the width of the wrapper
 */
const arrangeMarks = (
  marks: MarkType[],
  duration: number,
  wrapperWidth: number,
): MarkGroupType[] => {
  const groups: MarkGroupType[] = [];

  if (!duration) {
    return groups;
  }

  const sortedMarks = marks.sort((a, b) => a.timestamp - b.timestamp);

  sortedMarks.forEach((mark) => {
    const latestGroup = groups.pop();
    const currentMarkLeft = mark.timestamp * (wrapperWidth / duration);
    if (latestGroup) {
      if (isOverlap(latestGroup, currentMarkLeft)) {
        groups.push({
          marks: [...latestGroup.marks, mark],
          left: latestGroup.left,
        });
      } else {
        groups.push(latestGroup);
        groups.push({
          marks: [mark],
          left: currentMarkLeft,
        });
      }
    } else {
      groups.push({
        marks: [mark],
        left: currentMarkLeft,
      });
    }
  });

  // ensure the last group is not overflown and not overlap with previous group
  let isLastGroupOverflown = true;
  let isLastGroupOverlap = true;
  while (isLastGroupOverflown || isLastGroupOverlap) {
    const lastGroup = groups.pop();
    if (lastGroup) {
      if (isOverflown(lastGroup, wrapperWidth)) {
        lastGroup.left = wrapperWidth - measureGroupWidth(lastGroup);
      } else {
        isLastGroupOverflown = false;
      }

      const secondLastGroup = groups.pop();
      if (secondLastGroup) {
        const currentLeft =
          lastGroup.left === undefined
            ? wrapperWidth - measureGroupWidth(lastGroup)
            : lastGroup.left;
        if (isOverlap(secondLastGroup, currentLeft)) {
          // if secondLastGroup overlap with lastGroup, we move the latest item from secondLastGroup to the lastGroup
          const markToMove = secondLastGroup.marks.pop();

          if (markToMove) {
            lastGroup.marks.unshift(markToMove);
            lastGroup.left = markToMove.timestamp * (wrapperWidth / duration);
          }
        } else {
          isLastGroupOverlap = false;
        }

        if (secondLastGroup.marks.length > 0) {
          // if there still remaining mark in the secondLastGroup, add secondLastGroup back to the groups
          groups.push(secondLastGroup);
        }
      } else {
        isLastGroupOverlap = false;
      }

      groups.push(lastGroup);
    } else {
      isLastGroupOverflown = false;
      isLastGroupOverlap = false;
    }
  }
  return groups;
};

export default arrangeMarks;

import React, { PropsWithChildren, ReactElement } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export type FieldsetProps = {
  id?: string;
  legend: string;
};

const StyledFieldset = styled('fieldset')`
  border-style: unset;
  padding: 0;
`;

/**
 * Contains a logical grouping of fields described by the `title`
 * Has a 12 column grid, designed to work with {@link FormControlContainer}
 *
 * @example - Fields that stack on small screens but sit side-by-side on large screens
 * <Fieldset legend="Ordering">
 *   <FormControlContainer breakpoints={{ xs: 12, md: 6 }}>
 *     <TextField {...props} />
 *   </FormControlContainer>
 *   <FormControlContainer breakpoints={{ xs: 12, md: 6 }}>
 *     <TextField {...props} />
 *   </FormControlContainer>
 * </Fieldset>
 */
export const Fieldset = ({
  legend,
  id,
  children,
}: PropsWithChildren<FieldsetProps>): ReactElement => (
  <StyledFieldset id={id}>
    <Typography
      variant="subtitle1"
      component="legend"
      sx={{ p: 0 }}
      gutterBottom
    >
      {legend}
    </Typography>
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gap: 3.5,
        mb: 2,
        mt: 1,
      }}
    >
      {children}
    </Box>
  </StyledFieldset>
);

// TODO(FF-40): Delete this Dropzone component
import React, {
  Children,
  MouseEvent,
  PropsWithChildren,
  ReactElement,
  useMemo,
} from 'react';
import { styled, Theme, Typography } from '@mui/material';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import { faArrowCircleUp } from '@fortawesome/pro-light-svg-icons/faArrowCircleUp';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons/faCircleXmark';
import { Button } from '../Button/Button';
import { FormField, FormFieldProps } from '../FormField/FormField';
import { FaIcon } from '../../DataDisplay/Icon/FaIcon';

type PlaceholderPosition = 'topLeft' | 'top' | 'bottom';

const borderImage = (
  theme: Theme,
  isDragActive?: boolean,
  hasError?: boolean,
) => {
  let color = theme.palette.grey['500'];
  if (isDragActive) color = theme.palette.primary.main;
  if (hasError) color = theme.palette.error.main;
  const stroke = encodeURIComponent(color);
  return `"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='8' stroke='${stroke}' stroke-width='2' stroke-dasharray='3%2c 7' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e"`;
};

const Wrapper = styled('div', {
  shouldForwardProp: (prop) =>
    !['isDragActive', 'hasError'].includes(prop as string),
})<{ isDragActive?: boolean; hasError?: boolean }>`
  align-items: stretch;
  background-color: ${(props) => props.theme.palette.grey.A50};
  background-image: url(${(props) =>
    borderImage(props.theme, props.isDragActive, props.hasError)});
  border-radius: ${(props) => `${props.theme.shape.borderRadius}px`};
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  margin: ${(props) => `${props.theme.spacing(1)} 0`};
  min-height: 230px;
  max-height: 430px;
  overflow-y: auto;
  padding: ${(props) => props.theme.spacing(2)};
`;
Wrapper.displayName = 'DropzoneWrapper';

const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDragActive',
})<{ isDragActive: boolean }>((props) => ({
  flex: 1,
  outline: 'none',
  position: 'relative',
  display: 'flex',
  ...(props.isDragActive && {
    alignItems: 'center',
    justifyContent: 'center',
  }),
}));

const Invite = styled('div', {
  shouldForwardProp: (prop) =>
    !['hasChildren', 'placeholderPosition'].includes(prop as string),
})<{
  hasChildren?: boolean;
  disabled?: boolean;
  placeholderPosition?: PlaceholderPosition;
}>(({ hasChildren, theme, placeholderPosition, disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  bottom: hasChildren ? 'revert' : 0,
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'center',
    right: 0,
    ...(placeholderPosition === 'bottom' && {
      top: hasChildren ? 'revert' : 0,
      bottom: 0,
    }),
    ...(placeholderPosition === 'top' && {
      top: hasChildren ? '30px' : 0,
    }),
    ...(placeholderPosition === 'topLeft' &&
      hasChildren && {
        justifyContent: 'unset',
      }),
  },
  left: 0,
  ...(disabled && { opacity: 0.1 }),
}));

const DragActiveWrapper = styled(Invite, {
  shouldForwardProp: (prop) => prop !== 'isDragReject',
})<{
  isDragReject?: boolean;
}>(({ theme, isDragReject }) => ({
  gap: theme.spacing(1),
  color: theme.palette.grey['400'],
  flexDirection: 'column-reverse',
  alignItems: 'center',
  margin: 'auto',
  right: 0,
  justifyContent: 'center',
  ...(isDragReject && {
    backgroundColor: theme.palette.error.light,
    cursor: 'not-allowed',
  }),
}));

const ChildContainer = styled('div', {
  shouldForwardProp: (prop) =>
    !['isDragActive', 'placeholderPosition'].includes(prop as string),
})<{
  isDragActive?: boolean;
  placeholderPosition: PlaceholderPosition;
}>(({ theme, isDragActive, placeholderPosition }) => ({
  marginTop: '40px',
  [theme.breakpoints.up('sm')]: {
    ...(placeholderPosition === 'top' && {
      alignSelf: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
    }),
    ...(placeholderPosition === 'bottom' && {
      marginBottom: '40px',
      marginTop: 0,
    }),
  },
  ...(isDragActive && {
    background: theme.palette.grey['50'],
    opacity: 0.1,
  }),
}));

/** @deprecated - Use `@shuttlerock/uploading` components instead */
export type DropzoneProps = FormFieldProps & {
  id?: string;
  placeholderStart?: string;
  placeholderEnd?: string;
  placeholderPosition?: PlaceholderPosition;
  buttonLabel: string;
  dropLabel: string;
  rejectLabel: string;
  accept?: string[];
  onChange: (acceptedFiles: File[]) => void;
  onError: (error: string) => void;
  onBlur?: () => void;
} & Omit<DropzoneOptions, 'accept' | 'onDrop' | 'noClick' | 'noKeyboard'>;

/** @deprecated - Use `@shuttlerock/uploading` components instead */
export const Dropzone = ({
  id: inId,
  name,
  label,
  helperText,
  cornerHelperText,
  topHelperText,
  tooltip,
  error,
  placeholderPosition = 'bottom',
  placeholderStart,
  placeholderEnd,
  buttonLabel,
  dropLabel,
  rejectLabel,
  required,
  disabled,
  accept,
  onChange,
  onError,
  onBlur,
  loading,
  controlRef,
  children,
  ...useDropzoneProps
}: PropsWithChildren<DropzoneProps>): ReactElement => {
  const id = inId || name;
  const isUsingFileExtensions = useMemo(
    () => accept?.some((a) => a.includes('.')),
    [accept],
  );

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    onBlur?.();
    onChange(acceptedFiles);
    if (fileRejections) {
      fileRejections.map(({ errors }) =>
        onError(errors.map((e) => e.message).join()),
      );
    }
  };

  const { getInputProps, getRootProps, isDragActive, isDragReject, open } =
    useDropzone({
      ...useDropzoneProps,
      onDrop,
      accept,
      disabled,
      noClick: true,
      noKeyboard: true,
    });

  // Due to browser limitations `isDragReject` will erroneously return `true` when using file extensions to limit
  // the accepted file types. For this to work correctly we need to use MIME types instead.
  // See https://react-dropzone.js.org/#browser-limitations
  const isDragRejectKnown = !isUsingFileExtensions && isDragReject;

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    open();
  };

  return (
    <FormField
      fullWidth
      name={name}
      label={label}
      helperText={helperText}
      cornerHelperText={cornerHelperText}
      topHelperText={topHelperText}
      tooltip={tooltip}
      error={error}
      required={required}
      disabled={disabled}
      loading={loading}
      controlRef={controlRef}
    >
      <Wrapper
        data-testid={['DropzoneWrapper', id].filter(Boolean).join('-')}
        isDragActive={isDragActive}
        hasError={isDragRejectKnown || !!error}
        onBlur={onBlur}
      >
        <Container
          {...getRootProps()}
          id="dropzone"
          isDragActive={isDragActive}
        >
          <input
            {...getInputProps({
              id,
              'data-testid': id ? `${id}-input` : undefined,
            })}
          />
          {isDragActive ? (
            <DragActiveWrapper isDragReject={isDragRejectKnown}>
              <Typography variant="h3" component="p">
                {!isDragRejectKnown ? dropLabel : rejectLabel}
              </Typography>
              {!isDragRejectKnown ? (
                <FaIcon
                  icon={faArrowCircleUp}
                  color="primary"
                  sx={{ fontSize: '3em' }}
                />
              ) : (
                <FaIcon
                  icon={faCircleXmark}
                  color="error"
                  sx={{ fontSize: '3em' }}
                />
              )}
            </DragActiveWrapper>
          ) : (
            <Invite
              hasChildren={
                !!Children.toArray(children)?.filter(Boolean)?.length
              }
              disabled={disabled}
              placeholderPosition={placeholderPosition}
            >
              <span>
                <Typography
                  component="span"
                  align="center"
                  color="text.secondary"
                >
                  {placeholderStart}
                </Typography>
                <Typography
                  mb="2px"
                  mx={0.5}
                  p={0}
                  component={Button}
                  onClick={handleOpen}
                  disabled={disabled}
                  id={`${id ? `${id}-` : ''}dropzone-browse-local-button`}
                >
                  {buttonLabel}
                </Typography>
                <Typography
                  component="span"
                  align="center"
                  color="text.secondary"
                >
                  {placeholderEnd}
                </Typography>
              </span>
            </Invite>
          )}
          <ChildContainer
            isDragActive={isDragActive}
            placeholderPosition={placeholderPosition}
          >
            {children}
          </ChildContainer>
        </Container>
      </Wrapper>
    </FormField>
  );
};

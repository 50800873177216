import React, { ReactElement } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export type ShuttlerockIconProps = Omit<SvgIconProps, 'color'> & {
  color?: SvgIconProps['color'] | 'brand';
};

export const ShuttlerockIcon = ({
  color,
  ...props
}: ShuttlerockIconProps): ReactElement => {
  return (
    <SvgIcon
      {...props}
      {...(color && color !== 'brand' ? { color } : undefined)}
      viewBox="0 0 42 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        {...(color === 'brand' && { fill: '#E91D2E' })}
        d="M26.0099 36.4633C25.1962 37.3003 25.2256 38.6337 26.0687 39.4415C26.4804 39.8308 27.0097 40.0352 27.5391 40.0352C28.0978 40.0352 28.6468 39.821 29.0683 39.3928L36.3909 31.85C37.2045 31.013 37.1751 29.6893 36.3321 28.8815C35.4891 28.0737 34.1461 28.1029 33.3325 28.9399L26.0099 36.4633ZM22.9122 27.8109C22.0888 28.6382 22.0986 29.9716 22.9318 30.7891C23.3435 31.1882 23.8827 31.3926 24.4218 31.3926C24.9708 31.3926 25.5197 31.1784 25.9314 30.7697L40.6355 15.9176C41.4589 15.0904 41.4491 13.757 40.6159 12.9394C39.7826 12.1316 38.4397 12.1414 37.6162 12.9686L22.9122 27.8109ZM12.4625 7.50862C13.3056 6.70081 13.335 5.37717 12.5312 4.53043C11.7175 3.69342 10.3844 3.66423 9.53153 4.47204L0.699301 12.881C-0.143731 13.6889 -0.173138 15.0125 0.630682 15.8495C1.0424 16.2777 1.60115 16.5016 2.15991 16.5016C2.68925 16.5016 3.2088 16.3069 3.63031 15.9176L12.4625 7.50862ZM28.4507 3.72262C29.284 2.91481 29.3036 1.58144 28.4899 0.744428C27.6763 -0.0828475 26.3333 -0.102312 25.4903 0.715231L10.6196 15.1488C9.7864 15.9663 9.76679 17.2899 10.5804 18.1269C10.9921 18.5455 11.5411 18.7596 12.09 18.7596C12.6194 18.7596 13.1585 18.5649 13.5702 18.1659L28.4507 3.72262ZM40.5178 3.62529C41.3413 2.79802 41.3413 1.46465 40.508 0.647103C39.6748 -0.17044 38.3318 -0.170441 37.5084 0.656834L11.6881 26.4386C10.8647 27.2659 10.8647 28.5896 11.6979 29.4168C12.1096 29.8256 12.6488 30.03 13.1879 30.03C13.7271 30.03 14.276 29.8256 14.6877 29.4168L40.5178 3.62529Z"
      />
    </SvgIcon>
  );
};

/* eslint-disable no-console */
import * as Sentry from '@sentry/react';

interface CreateCaptureFnOptions {
  /**
   * The Sentry severity level to use when capturing the exception.
   */
  level: Sentry.SeverityLevel;
  /**
   * The console log level to log the exception at if not sampled for sentry.
   */
  consoleLogLevel: 'error' | 'warn' | 'log' | 'info' | 'debug';
  /**
   * The sample rate between 0 and 1, where 1 is always sampled and 0 is never sampled.
   *
   * As sentry charges per error, we sample errors to prevent sending too many errors to sentry.
   * Translation warnings are particularly problematic as they can quickly exhaust the Sentry error budget.
   */
  sampleRate: number;
}

const createCaptureFn =
  ({ level, consoleLogLevel, sampleRate }: CreateCaptureFnOptions) =>
  (exception: unknown) => {
    const shouldLogToSentry = Math.random() < sampleRate;

    if (process.env.NODE_ENV === 'production' && shouldLogToSentry) {
      Sentry.captureException(exception, { level });
    } else {
      console[consoleLogLevel](exception);
    }
  };

export const captureException = createCaptureFn({
  level: 'error',
  consoleLogLevel: 'error',
  sampleRate: 1,
});

export const captureWarning = createCaptureFn({
  level: 'error',
  consoleLogLevel: 'warn',
  sampleRate: 0.05,
});

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// Dayjs locale
// Need to import locale data for dayjs, ref: https://github.com/iamkun/dayjs/issues/235
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import 'dayjs/locale/mi';
import 'dayjs/locale/ru';

// Import translations
import en from './en.yml';
import fr from './fr.yml';
import ja from './ja.yml';
import mi from './mi.yml';
import ru from './ru.yml';

// Default Options to add to the global instance, until we replace all usages
import { defaultOptions } from './createInstance';

// Combine into resources object
const resources = {
  en: en.en,
  fr: fr.fr,
  ja: ja.ja,
  mi: mi.mi,
  ru: ru.ru,
};

i18n
  .use(initReactI18next) // React binding
  .use(LanguageDetector) // Detects languages automatically
  .init({ ...defaultOptions, resources });

// TODO: find a way to set locale fallback for dayjs
const dayjsLocale = ['en', 'ja', 'mi', 'ru'].includes(i18n.language)
  ? i18n.language
  : 'en';
dayjs.locale(dayjsLocale as 'en' | 'ja' | 'mi' | 'ru');

/** @deprecated - please use `createInstance` instead */
// eslint-disable-next-line import/no-default-export
export default i18n;

export { createInstance } from './createInstance';

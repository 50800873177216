/* eslint-disable react/jsx-props-no-spreading */
import { keyframes } from '@mui/system';
import Typography, { TypographyProps } from '@mui/material/Typography';

const glitchNormal = keyframes`
  100% {
     --text-glitch-y1: 10%;
     --text-glitch-y2: 50%;
     --text-glitch-y3: 70%;
     --text-glitch-y4: 95%;
   }
`;

const glitchLeft = keyframes`
  0%, 10% {
    transform: translateX(5px);
    text-shadow: -5px 0 red;
  }
  11% {
    transform: none;
    text-shadow: none;
  }
`;

const glitchRight = keyframes`
  0%, 10% {
    transform: translateX(5px);
    text-shadow: 5px 0 cyan;
  }
  11% {
    transform: none;
    text-shadow: none;
  }
`;

type GlitchTextProps = {
  children: string;
} & Omit<TypographyProps, 'children'>;

// Modified from https://codepen.io/edalgrin/pen/rNWYEbw
export const GlitchText = ({ children, ...props }: GlitchTextProps) => {
  const textContent = `"${children}"`;
  return (
    <Typography
      {...props}
      sx={{
        ...props.sx,
        position: 'relative',
        color: 'transparent',
        '--text-glitch-y1': '30%',
        '--text-glitch-y2': '35%',
        '--text-glitch-y3': '50%',
        '--text-glitch-y4': '55%',
        '--text-glitch-time': '5s',
        animation: `${glitchNormal} 0.2s infinite`,

        '&:before, &:after': {
          content: textContent,
          position: 'absolute',
          left: 0,
          top: 0,
          color: 'white',
        },

        '&:before': {
          clipPath: `polygon(
            0% 0%,
            100% 0%,
            100% var(--text-glitch-y1),
            0% var(--text-glitch-y1),
            0% var(--text-glitch-y2),
            100% var(--text-glitch-y2),
            100% var(--text-glitch-y3),
            0% var(--text-glitch-y3),
            0% var(--text-glitch-y4),
            100% var(--text-glitch-y4),
            100% 100%,
            0% 100%
          )`,
          animation: `${glitchRight} var(--text-glitch-time, 1s) infinite`,
        },

        '&:after': {
          clipPath: `polygon(
            0% var(--text-glitch-y1),
            100% var(--text-glitch-y1),
            100% var(--text-glitch-y2),
            0% var(--text-glitch-y2),
            0% var(--text-glitch-y3),
            100% var(--text-glitch-y3),
            100% var(--text-glitch-y4),
            0% var(--text-glitch-y4)
          )`,
          animation: `${glitchLeft} var(--text-glitch-time, 1s) infinite`,
        },
      }}
    >
      {children}
    </Typography>
  );
};

import React, {
  ElementType,
  MouseEventHandler,
  MouseEvent,
  ReactElement,
  PropsWithChildren,
} from 'react';
import MuiButton, { LoadingButtonTypeMap } from '@mui/lab/LoadingButton';
import { useSafeState } from '@react-hookz/web';
import { OverrideProps } from '@mui/material/OverridableComponent';
import {
  Box,
  ExtendButtonBaseTypeMap,
  Theme,
  useMediaQuery,
} from '@mui/material';

export type ButtonProps<C extends ElementType = 'button'> = OverrideProps<
  ExtendButtonBaseTypeMap<LoadingButtonTypeMap>,
  C
> & {
  component?: C;
  /**
   * The size to use on medium to large screens. NOTE: This will have no effect if the `size` prop is supplied
   * @default 'medium'
   */
  defaultSize?: ButtonProps['size'];
  /**
   * The size to use on small / extra small screens. NOTE: This will have no effect if the `size` prop is supplied
   * @default 'large'
   */
  smallSize?: ButtonProps['size'];
};
type ClickHandler = (e: MouseEvent<unknown>) => void | Promise<void>;

const Icon = ({
  mr,
  ml,
  children,
}: PropsWithChildren<{ mr: number; ml: number }>) => (
  <Box component="span" sx={{ display: 'inherit', mr, ml }}>
    {children}
  </Box>
);

export const Button = <C extends ElementType = 'button'>({
  size,
  defaultSize,
  smallSize = 'large',
  startIcon,
  endIcon,
  children,
  ...props
}: ButtonProps<C>): ReactElement => {
  const isSmallScreen = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm'),
  );
  const [loading, setLoading] = useSafeState(false);
  const effectiveLoading = props.loading ?? loading;
  const effectiveSize = size || (isSmallScreen ? smallSize : defaultSize);

  const handleClick: MouseEventHandler<unknown> = async (event) => {
    if (props.onClick) {
      try {
        const result = (props.onClick as ClickHandler)(event);

        if (result instanceof Promise) {
          setLoading(true);
          await result;
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <MuiButton
      {...props}
      onClick={handleClick}
      loading={effectiveLoading}
      size={effectiveSize}
    >
      {startIcon ? (
        <Icon mr={1} ml={-0.5}>
          {startIcon}
        </Icon>
      ) : null}
      {children}
      {endIcon ? (
        <Icon mr={-0.5} ml={1}>
          {endIcon}
        </Icon>
      ) : null}
    </MuiButton>
  );
};

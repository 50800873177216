import { styled } from '@mui/material/styles';
import { MARK_SIZE } from './constants';

const Mark = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active',
})<{
  disabled?: boolean;
  active?: boolean;
}>`
  position: absolute;
  width: ${MARK_SIZE}px;
  height: ${MARK_SIZE}px;
  cursor: ${(props) => (props.disabled ? 'disabled' : 'pointer')};
  border-radius: 50%;
  background-color: ${(props) =>
    props.active
      ? props.theme.palette.primary.main
      : props.theme.palette.grey.A200};
`;

export default Mark;

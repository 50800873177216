import {
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Button, SnackbarContent, Stack } from '@mui/material';
import { ShuttlerockIcon, Snackbar } from '@shuttlerock/mui-components';

export type Entrypoint = {
  name: string;
  url: string;
};

export type AutoUpdateProps = {
  fetchInterval: number;
};

const etagMap = new Map<string, string>();

const compareEtags = async ({ name, url }: Entrypoint) => {
  try {
    const response = await fetch(url);
    if (response.status !== 200) {
      throw new Error(`Failed to fetch entrypoint from ${url}`);
    }
    const latestEtag = response.headers.get('etag');
    if (!latestEtag) {
      throw new Error(`Entrypoint ${url} has no etag to compare!`);
    }

    const existingEtag = etagMap.get(name);
    if (!existingEtag) {
      etagMap.set(name, latestEtag);
      // First (successful) fetch, store the current etag and move on
      return false;
    }

    if (latestEtag !== existingEtag) {
      etagMap.set(name, latestEtag);
      return true;
    }

    return false;
  } catch {
    // do nothing
    return false;
  }
};

const hasChanges = async (entrypoints: Entrypoint[]) => {
  const changes = await Promise.all(entrypoints.map(compareEtags));
  return changes.some((x) => !!x);
};

const useEvent = <Params extends unknown[], Result>(
  callback: (...args: Params) => Result,
): ((...args: Params) => Result) => {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;
  return useCallback((...args: Params) => callbackRef.current(...args), []);
};

const Message = forwardRef<HTMLDivElement, unknown>((_, ref): ReactElement => {
  const onClick = () => {
    const url = new URL(window.location.toString());
    url.searchParams.set('updated', 'true');
    window.history.replaceState(null, '', url.toString());
    window.location.reload();
  };

  return (
    <SnackbarContent
      ref={ref}
      sx={{ p: 0 }}
      message={
        <Stack alignItems="flex-end">
          <Stack direction="row" alignItems="center" gap={1} px={2}>
            <ShuttlerockIcon color="brand" />
            ShuttlerockCloud is ready to update!
          </Stack>
          <Button color="inherit" size="small" onClick={onClick} sx={{ mt: 1 }}>
            Update now
          </Button>
        </Stack>
      }
    />
  );
});

export const AutoUpdate = ({ fetchInterval }: AutoUpdateProps): null => {
  const fetchIntervalRef = useRef<ReturnType<typeof setInterval>>();
  const snackbarRef = useRef<ReturnType<typeof Snackbar.enqueue>>();

  const checkForChanges = useEvent(async () => {
    if (snackbarRef.current) return;

    if (
      !(await hasChanges([
        { name: 'index', url: '/index.html' },
        { name: 'config', url: '/config/env-config.js' },
        { name: 'config-es', url: '/config-es/env-config.js' },
      ]))
    ) {
      return;
    }

    if (fetchIntervalRef.current) {
      clearInterval(fetchIntervalRef.current);
    }

    snackbarRef.current = Snackbar.enqueue(<Message />, {
      key: 'AutoReload',
      persist: true,
      preventDuplicate: true,
      style: { justifyContent: 'center' },
    });
  });

  useEffect(() => {
    if (typeof window === 'undefined' || !window.location?.reload) {
      return () => {};
    }

    let pausedAt: Date | null = null;

    const onVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        if (pausedAt) {
          const pausedFor = new Date().getTime() - pausedAt.getTime();
          if (pausedFor > fetchInterval) {
            await checkForChanges();
          }
        }
        fetchIntervalRef.current = setInterval(checkForChanges, fetchInterval);
      }
      if (document.visibilityState === 'hidden') {
        pausedAt = new Date();
        if (fetchIntervalRef.current) clearInterval(fetchIntervalRef.current);
      }
    };

    checkForChanges();
    window.addEventListener('visibilitychange', onVisibilityChange);
    fetchIntervalRef.current = setInterval(checkForChanges, fetchInterval);

    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChange);
      if (fetchIntervalRef.current) clearInterval(fetchIntervalRef.current);
    };
  }, [checkForChanges, fetchInterval]);

  return null;
};

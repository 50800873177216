import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { styled } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React, { forwardRef } from 'react';

export type FaIconProps = {
  icon: IconDefinition;
  /**
   * Determines opacity to use for the primary path in duotone icons (should be a number between 0 and 1)
   * @default 1.0
   */
  faPrimaryOpacity?: number;
  /**
   * Determines opacity to use for the secondary path in duotone icons (should be a number between 0 and 1)
   * @default 0.4
   */
  faSecondaryOpacity?: number;
} & Omit<SvgIconProps, 'viewBox' | 'css'>;

const Path = styled('path')<{ opacity: number }>(({ opacity }) => ({
  opacity,
}));

/**
 * MUI Wrapper for FontAwesome Icons (see https://mui.com/components/icons/#font-awesome) to allow for correct sizing (and to unlock the `sx` prop)
 *
 * **This should be preferred over `@fortawesome/react-fontawesome.FontAwesomeIcon`**
 */
export const FaIcon = forwardRef<SVGSVGElement, FaIconProps>(
  (
    {
      icon,
      faPrimaryOpacity = 1,
      faSecondaryOpacity = 0.4,
      fontSize = 'inherit',
      ...props
    },
    ref,
  ) => {
    const {
      icon: [width, height, , , svgPathData],
    } = icon;

    return (
      <SvgIcon
        ref={ref}
        fontSize={fontSize}
        {...props}
        viewBox={`0 0 ${width} ${height}`}
      >
        {typeof svgPathData === 'string' ? (
          <path d={svgPathData} />
        ) : (
          /**
           * A multi-path Font Awesome icon seems to imply a duotune icon. The 0th path seems to
           * be the faded element (referred to as the "secondary" path in the Font Awesome docs)
           * of a duotone icon. 40% is the default opacity.
           *
           * @see https://fontawesome.com/how-to-use/on-the-web/styling/duotone-icons#changing-opacity
           */
          svgPathData.map((d: string, i: number) => (
            <Path
              key={d}
              className={`MuiFaIcon-${i === 0 ? 'Secondary' : 'Primary'}Path`}
              opacity={i === 0 ? faSecondaryOpacity : faPrimaryOpacity}
              d={d}
            />
          ))
        )}
      </SvgIcon>
    );
  },
);

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { initialiseLogging } from '@shuttlerock/observability';
import { ConfigService } from '@creative-foundation/configuration';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { routes } from 'virtual:routes';
import { Loading } from './components/Loading/Loading';

initialiseLogging(
  ConfigService.SENTRY_ENABLED(),
  ConfigService.ENVIRONMENT(),
  ConfigService.APP_VERSION(),
);

const router = createBrowserRouter(routes, {
  future: {
    v7_normalizeFormMethod: true,
    v7_fetcherPersist: true,
    v7_relativeSplatPath: true,
  },
});

const container = document.getElementById('root');

if (!container) {
  throw new Error('React failed to render. Could not find `root` element');
}

const root = createRoot(container);

root.render(
  <StrictMode>
    <RouterProvider router={router} fallbackElement={<Loading />} />
  </StrictMode>,
);

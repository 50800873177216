import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { captureConsoleIntegration } from '@sentry/integrations';

const ignoreErrors = [
  'ResizeObserver loop limit exceeded', // https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402
  /\[Shuttlerock Cloud\] [.*] version/, // Our own console version logging
  '[LaunchDarkly] Error on stream connection', // Ignore stream connection errors until LaunchDarkly issue is resolved (https://github.com/launchdarkly/js-client-sdk/issues/249)
];

const denyUrls = [
  /https?:\/\/(api\.)?(hubspot|hubapi)\.com/,
  /https?:\/\/(cdn\.)?segment\.com/,
  /https?:\/\/(www\.)?google-analytics\.com/,
  /https?:\/\/(stats\.g\.)?doubleclick\.net/,
  /https?:\/\/localhost/,
];

export function initialiseLogging(
  enabled: boolean,
  environment: string,
  release: string,
) {
  if (!enabled) {
    return;
  }

  Sentry.init({
    beforeSend: (event) => {
      if (event?.contexts?.device?.family === 'Spider') return null;
      if (event?.contexts?.browser?.name === 'HeadlessChrome') return null;

      return event;
    },
    dsn: 'https://8079511cc7fb4c4f9f0cf312f4a28a28@o879838.ingest.sentry.io/6110747',
    environment,
    release,
    integrations: [
      new Sentry.BrowserTracing(),
      captureConsoleIntegration({
        levels: ['warn', 'error'],
      }),
    ],
    ignoreErrors,
    denyUrls,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

export type LoggingUser = {
  integrationKey: string | undefined;
  email?: string;
};

export function useIdentifyLoggingUser(user: LoggingUser | undefined) {
  useEffect(() => {
    const input = user
      ? {
          id: user.integrationKey,
          email: user.email,
        }
      : null;
    Sentry.setUser(input);
  }, [user]);
}

import { ReactElement, useEffect } from 'react';
import { useSafeState } from '@react-hookz/web';
import { Routes } from '@shuttlerock/router';
import { captureException } from '@shuttlerock/observability';
import { ErrorPage } from './ErrorPage';
import { useTranslation } from '../../i18n';

const isError = (x: unknown): x is Error =>
  x instanceof Error || (typeof x === 'object' && !!x && 'message' in x);

export const TopLevelErrorBoundary = ({
  error,
}: {
  error: unknown;
}): ReactElement => {
  const { t } = useTranslation('errorPage');
  const [hasRefreshedCache, setHasRefreshedCache] = useSafeState(false);

  const onClick = () => {
    window.location.reload();
  };

  useEffect(() => {
    captureException(error);
    Promise.all([
      fetch('/config/env-config.js', { cache: 'reload' }),
      fetch('/config-es/env-config.js', { cache: 'reload' }),
    ])
      .catch(captureException)
      .finally(() => {
        const url = new URL(window.location.toString());
        // When we see a "ChunkLoadError" we can assume that an update has been
        // released but the user is still on an old version so, we try to auto-refresh
        // for them, and use a query param to guard against an infinite reload loop
        if (
          isError(error) &&
          error?.message?.includes('ChunkLoadError') &&
          !url.searchParams.get('r')
        ) {
          url.searchParams.set('r', '1');
          window.history.replaceState(null, '', url.toString());
          window.location.reload();
        } else {
          setHasRefreshedCache(true);
        }
      });
    // We only want this effect to run on first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return hasRefreshedCache ? (
    <ErrorPage
      title={t('default:title')}
      body={t('default:defaultMessage')}
      primaryButton={{
        text: t('default:button:primary'),
        onClick,
      }}
      secondaryButton={{
        text: t('default:button:secondary'),
        route: Routes.Home,
      }}
    />
  ) : (
    <div />
  );
};

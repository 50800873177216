export const shuttlerockBlue = Object.freeze({
  50: '#E1E8EF',
  100: '#D0E0F1',
  200: '#A3C3E6',
  300: '#7FA9D7',
  400: '#5894D5',
  500: '#1C75D4',
  600: '#1A5CA2',
  700: '#16487E',
  800: '#133963',
  900: '#14304D',
});

export const green = Object.freeze({
  50: '#E0E6E2',
  100: '#AEC1B5',
  200: '#8EB49B',
  300: '#6AAF82',
  400: '#49AB6C',
  500: '#36A05B',
  600: '#2B824A',
  700: '#29603C',
  800: '#254630',
  900: '#233428',
});

export const red = Object.freeze({
  50: '#F4F1F1',
  100: '#E9DDDE',
  200: '#D6B3B6',
  300: '#CD898F',
  400: '#EC7A83',
  500: '#EC5562',
  600: '#BC2F3B',
  700: '#8A2E36',
  800: '#682C31',
  900: '#4C292C',
});

export const amber = Object.freeze({
  50: '#F5F3F0',
  100: '#EDE3D4',
  200: '#E0CAA3',
  300: '#DDB878',
  400: '#DFA749',
  500: '#E69A1A',
  600: '#AD781F',
  700: '#835E21',
  800: '#634B21',
  900: '#47381F',
});

export const purple = Object.freeze({
  50: '#F9F8FB',
  100: '#F0EDF7',
  200: '#E1D9F2',
  300: '#D3C6F0',
  400: '#B198EC',
  500: '#926EE4',
  600: '#683CCD',
  700: '#5737A0',
  800: '#453271',
  900: '#322848',
});

export const grey = Object.freeze({
  50: '#141414',
  100: '#222222',
  200: '#323232',
  300: '#4e4e4e',
  400: '#646464',
  500: '#909090',
  600: '#a8a8a8',
  700: '#bdbdbd',
  800: '#e0e0e0',
  900: '#fdfdfd',
  A50: '#000000',
  A100: 'rgba(255, 255, 255, .05)',
  A200: 'rgba(255, 255, 255, .15)',
  A300: 'rgba(255, 255, 255, .3)',
  A400: 'rgba(255, 255, 255, .4)',
  A500: 'rgba(255, 255, 255, .5)',
  A600: 'rgba(255, 255, 255, .6)',
  A700: 'rgba(255, 255, 255, .7)',
  A800: 'rgba(255, 255, 255, .8)',
  A900: 'rgba(255, 255, 255, 0.9)',
});

export const gradient = Object.freeze({
  main: 'linear-gradient(89.99deg, #8B35CE 7.91%, #1132B7 99.99%);',
  hover: 'linear-gradient(89.99deg, #7341C0, #2B64A0);',
  background:
    'linear-gradient(155.75deg, #17F2F2 8.22%, #2758D7 57.63%, #B424E8 103.09%)',
  blueToPurple:
    'linear-gradient(100.23deg, #4FA6F8 -0.53%, #9F48F1 70.81%, #E966DC 115.3%)',
  purpleToBlue: 'linear-gradient(133deg, #8A4BBC 21.36%, #3E55B9 83.84%)',
  pinkToOrange:
    'linear-gradient(121deg, #ED70AC -6.44%, #ED6F5D 54.87%, #F6C554 108.68%)',
});

import Stack from '@mui/material/Stack';
import { keyframes } from '@mui/system';
import { useGlobalTheme } from '@shuttlerock/mui-components';

import LoadingSvg from './Loading.svg';

const pulse = keyframes`
  0%, 100% {
    transform: scale(0.95);
    opacity: 0.6;
  }

  70% {
    transform: scale(1);
    opacity: 1;
  }
`;

export const Loading = () => {
  const { theme } = useGlobalTheme();

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        position: 'absolute',
        inset: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: theme.palette.background.default,
        svg: {
          display: 'block',
          margin: 'auto',
          maxWidth: '144px',
          animation: `${pulse} 2s infinite`,
        },
      }}
    >
      <LoadingSvg />
    </Stack>
  );
};

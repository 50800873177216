/**
 * An OAuth2 error will come from the authorization server and will have at least an `error` property which will
 * be the error code. And possibly an `error_description` property
 *
 * See: https://openid.net/specs/openid-connect-core-1_0.html#rfc.section.3.1.2.6
 */
export class OAuthError extends Error {
  constructor(
    public error: string,
    public error_description?: string,
  ) {
    super(error_description || error);

    // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, OAuthError.prototype);
  }
}

export class FetchUserError extends Error {
  constructor(public error: string) {
    super(`Failed to fetch user data: ${error}`);

    Object.setPrototypeOf(this, FetchUserError.prototype);
  }
}

function isObjectError(
  error: unknown,
): error is { error?: unknown; error_description?: unknown } {
  return typeof error === 'object' && error !== null && 'error' in error;
}

function normalizeError(fallbackMessage: string) {
  return (error: unknown): Error => {
    if (error instanceof Error) {
      return error;
    }
    // try to check errors of the following form: {error: string; error_description?: string}
    if (isObjectError(error) && typeof error.error === 'string') {
      if (
        'error_description' in error &&
        typeof error.error_description === 'string'
      ) {
        return new OAuthError(error.error, error.error_description);
      }
      return new OAuthError(error.error);
    }
    return new Error(fallbackMessage);
  };
}

export const TokenError: (error: unknown) => Error = normalizeError(
  'Get access token failed',
);

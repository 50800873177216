import { isValidElement, ReactNode } from 'react';
import {
  enqueueSnackbar,
  closeSnackbar,
  SnackbarKey,
  SnackbarMessage,
  OptionsObject,
  ProviderContext,
  OptionsWithExtraProps,
  VariantType,
} from 'notistack';

export enum SnackbarVariant {
  DEFAULT = 'default',
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  PROGRESS = 'progress',
}

export type SnackbarProps = Omit<OptionsObject, 'variant'>;
export type SnackbarFn = (
  message: SnackbarMessage,
  options?: SnackbarProps,
) => SnackbarKey;

export type SnackbarMethods = {
  close: typeof closeSnackbar;
  enqueue: typeof enqueueSnackbar;
  error: SnackbarFn;
  info: SnackbarFn;
  success: SnackbarFn;
  warning: SnackbarFn;
  progress: SnackbarFn;
};

const isReactNode = (value: unknown): value is ReactNode =>
  value !== null && typeof value === 'object' && isValidElement(value);

const isSnackbarMessage = (value: unknown): value is SnackbarMessage =>
  typeof value === 'string' || isReactNode(value);

export const Snackbar: SnackbarMethods = {
  enqueue<V extends VariantType>(
    arg0:
      | SnackbarMessage
      | (OptionsWithExtraProps<V> & { message?: SnackbarMessage }),
    arg1?: OptionsWithExtraProps<V>,
  ) {
    if (isSnackbarMessage(arg0)) {
      return enqueueSnackbar(arg0, arg1);
    }
    return enqueueSnackbar(arg0);
  },
  close(key) {
    return closeSnackbar(key);
  },
  error(message, options) {
    return enqueueSnackbar(message, {
      ...options,
      variant: SnackbarVariant.ERROR,
    });
  },
  info(message, options) {
    return enqueueSnackbar(message, {
      ...options,
      variant: SnackbarVariant.INFO,
    });
  },
  success(message, options) {
    return enqueueSnackbar(message, {
      ...options,
      variant: SnackbarVariant.SUCCESS,
    });
  },
  warning(message, options) {
    return enqueueSnackbar(message, {
      ...options,
      variant: SnackbarVariant.WARNING,
    });
  },
  progress(message, options) {
    return enqueueSnackbar(message, {
      variant: SnackbarVariant.PROGRESS,
      persist: true,
      ...options,
    });
  },
} as const;

/** @deprecated - Use {@link Snackbar} directly */
export type UseSnackbar = {
  /** @deprecated - Use {@link Snackbar.close} directly */
  closeSnackbar: ProviderContext['closeSnackbar'];
  /** @deprecated - Use {@link Snackbar.enqueue} directly */
  enqueueSnackbar: SnackbarFn;
  /** @deprecated - Use {@link Snackbar.error} directly */
  enqueueError: SnackbarFn;
  /** @deprecated - Use {@link Snackbar.info} directly */
  enqueueInfo: SnackbarFn;
  /** @deprecated - Use {@link Snackbar.success} directly */
  enqueueSuccess: SnackbarFn;
  /** @deprecated - Use {@link Snackbar.warning} directly */
  enqueueWarning: SnackbarFn;
  /** @deprecated - Use {@link Snackbar.progress} directly */
  enqueueProgress: SnackbarFn;
};

/** @deprecated - Use {@link Snackbar} directly */
export const useSnackbar = (): UseSnackbar => {
  return {
    closeSnackbar: Snackbar.close,
    enqueueSnackbar: Snackbar.enqueue,
    enqueueError: Snackbar.error,
    enqueueInfo: Snackbar.info,
    enqueueSuccess: Snackbar.success,
    enqueueWarning: Snackbar.warning,
    enqueueProgress: Snackbar.progress,
  };
};

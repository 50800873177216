import {
  AccessDeniedError,
  NotFoundError,
} from '@creative-foundation/shell-components';
import { ReactElement } from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { AccessDeniedPage } from './AccessDeniedPage';
import { TopLevelErrorBoundary } from './TopLevelErrorBoundary';
import { GonePage } from './GonePage';
import { NotFoundPage } from './NotFoundPage';
import { Loading } from '../Loading/Loading';

type ErrorResponse = {
  status: number;
  statusText: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  error?: Error;
  internal: boolean;
};

function isResponseStatus(
  error: unknown,
  status: number | number[],
): error is ErrorResponse {
  if (!isRouteErrorResponse(error)) return false;
  const statues = Array.isArray(status) ? status : [status];
  return statues.includes(error.status);
}

export const ErrorBoundaryFallback = (): ReactElement => {
  const error = useRouteError();
  // When calling `loginWithRedirect` the error boundary is rendered briefly before the redirect happens.
  // In this case we show a loading indicator instead of an "Access Denied" error.
  if (isResponseStatus(error, 401) && error.data === 'login_required') {
    return <Loading />;
  }
  if (
    error instanceof AccessDeniedError ||
    isResponseStatus(error, [401, 403])
  ) {
    return <AccessDeniedPage />;
  }
  if (error instanceof NotFoundError || isResponseStatus(error, 404)) {
    return <NotFoundPage />;
  }
  if (isResponseStatus(error, 410)) {
    return <GonePage message={error.data} />;
  }
  return <TopLevelErrorBoundary error={error} />;
};

/* eslint-disable */
import * as GraphApi from '@shuttlerock/api-types/federated';
export * from '@shuttlerock/api-types/federated';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ClientAccountQueryVariables = GraphApi.Exact<{
  key: GraphApi.Scalars['ID'];
}>;


export type ClientAccountQuery = { __typename?: 'Query', clientAccount: { __typename?: 'ClientAccount', key: string, enablePlatformAI: boolean } };


export const ClientAccountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ClientAccount"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"key"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"api"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"EnumValue","value":"federated"}}]}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"clientAccount"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"key"},"value":{"kind":"Variable","name":{"kind":"Name","value":"key"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"key"}},{"kind":"Field","name":{"kind":"Name","value":"enablePlatformAI"}}]}}]}}]} as unknown as DocumentNode<ClientAccountQuery, ClientAccountQueryVariables>;

/**
 * __useClientAccountQuery__
 *
 * To run a query within a React component, call `useClientAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientAccountQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useClientAccountQuery(baseOptions: Apollo.QueryHookOptions<ClientAccountQuery, ClientAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientAccountQuery, ClientAccountQueryVariables>(ClientAccountDocument, options);
      }
export function useClientAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientAccountQuery, ClientAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientAccountQuery, ClientAccountQueryVariables>(ClientAccountDocument, options);
        }
export type ClientAccountQueryHookResult = ReturnType<typeof useClientAccountQuery>;
export type ClientAccountLazyQueryHookResult = ReturnType<typeof useClientAccountLazyQuery>;
export type ClientAccountQueryResult = Apollo.QueryResult<ClientAccountQuery, ClientAccountQueryVariables>;
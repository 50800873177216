import { Routes } from '@shuttlerock/router';
import { ErrorPage } from './ErrorPage';
import { useTranslation } from '../../i18n';

export const NotFoundPage = () => {
  const { t } = useTranslation('errorPage');
  return (
    <ErrorPage
      title={t('notFound:title')}
      body={t('notFound:defaultMessage')}
      primaryButton={{
        text: t('notFound:button:primary'),
        route: Routes.External.ContactSupport,
      }}
      secondaryButton={{
        text: t('notFound:button:secondary'),
        route: Routes.Home,
      }}
    />
  );
};

import { Box, styled } from '@mui/material';
import React, {
  useEffect,
  useState,
  ReactElement,
  SyntheticEvent,
} from 'react';
import { CircularProgress } from '../../Feedback/CircularProgress/CircularProgress';
import useFullscreenControlState from '../MediaControl/useFullscreenControlState';
import { StaticMediaControls } from './StaticMediaControls';

export type UseImagePreviewProps = {
  src: string;
  alt?: string;
  fullScreenMode?: boolean;
  onClickFullscreen?: () => void;
  onError?: (event: SyntheticEvent<HTMLImageElement>) => void;
  onToggleFullscreenControl?: (toggle: boolean) => void;
};

export type ImagePreviewState = {
  showFullscreenControl: boolean;
  toggleFullscreenControl: () => void;
};

const Image = styled('img')`
  position: absolute;
  background: repeating-conic-gradient(
      rgba(0, 0, 0, 0.2) 0% 25%,
      rgba(255, 255, 255, 0.35) 0% 50%
    )
    0%/24px 24px;
`;

export const useImagePreview = ({
  alt,
  fullScreenMode,
  onClickFullscreen,
  onError,
  onToggleFullscreenControl,
  ...props
}: UseImagePreviewProps): [ReactElement, ReactElement, ImagePreviewState] => {
  const {
    showControl: showFullscreenControl,
    toggleControl: toggleFullscreenControl,
  } = useFullscreenControlState({
    onChange: onToggleFullscreenControl,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
  }, [props.src]);

  const Preview = (
    <Box
      sx={{
        position: 'absolute',
        width: 1,
        height: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Image
        {...props}
        alt={alt}
        onLoad={() => setLoading(false)}
        onError={onError}
        sx={{ display: loading ? 'none' : 'block' }}
      />
      {loading ? (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress value={60} size={48} />
        </Box>
      ) : null}
    </Box>
  );

  const Controls = (
    <StaticMediaControls
      fullScreenMode={!!fullScreenMode}
      onClickFullscreen={() => {
        toggleFullscreenControl();
        onClickFullscreen?.();
      }}
    />
  );

  return [
    Preview,
    Controls,
    { showFullscreenControl, toggleFullscreenControl },
  ];
};

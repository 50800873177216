import { FieldPolicy, Reference } from '@apollo/client';
import { mergeWith } from 'lodash-es';

type KeyArgs = FieldPolicy['keyArgs'];

type PaginatedResult<T = Reference> = {
  nodes: T[];
  // Rails API uses `metadata` for pagination
  metadata?: {
    currentPage: number;
  };
  // Go API uses `meta` for pagination
  meta?: {
    pageIndex: number;
  };
};

const mergeArrays = (objValue: unknown, srcValue: unknown) => {
  return Array.isArray(objValue) ? objValue.concat(srcValue) : undefined;
};

// TODO: it would be **much** better if we could use one of apollo's built-in policies (preferably `relayStylePagination`)
//  but this would require a **major** overhaul on the back-end
export const paginationFieldPolicy = <T = Reference>(
  keyArgs: KeyArgs = false,
): FieldPolicy<PaginatedResult<T>> => ({
  keyArgs,
  merge(existing, incoming) {
    const existingPage =
      existing?.metadata?.currentPage || existing?.meta?.pageIndex || 0;
    const incomingPage =
      incoming?.metadata?.currentPage || incoming?.meta?.pageIndex || 0;

    if (existingPage >= incomingPage) {
      return incoming;
    }

    return mergeWith({}, existing, incoming, mergeArrays);
  },
});

export const unshiftFieldPolicy = (keyArgs?: KeyArgs): FieldPolicy => ({
  keyArgs,
  // eslint-disable-next-line @typescript-eslint/default-param-last
  merge(existing = [], incoming) {
    return [...incoming, ...existing];
  },
});

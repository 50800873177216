/* eslint-disable @typescript-eslint/no-throw-literal, @typescript-eslint/no-use-before-define */
import { ApolloClient } from '@apollo/client';
import { User } from '@shuttlerock/auth-types';
import { Client, ClientGroup, ClientType } from '@shuttlerock/api-types/order';
import { captureException } from '@shuttlerock/observability';
import { json } from 'react-router-dom';
import {
  ClientAccountContext,
  ClientBrand,
  ClientAccount,
  DefaultClientAccount,
} from './types';
import { UserAccountsDocument } from './generated/order/graphql';
import { ClientAccountDocument } from './generated/federated/graphql';
import { singleton } from './sharescope';

const cache = singleton(
  'client-account-context/cache',
  new Map<User, ClientAccountContext>(),
);

export async function getUserClientAccount(
  apiClient: ApolloClient<unknown>,
  user: User | undefined,
): Promise<ClientAccountContext> {
  if (!user) {
    return { isMultiAccountUser: false, isMultiBrandUser: false };
  }

  if (user.hasInternalRole) {
    return {
      isMultiAccountUser: true,
      isMultiBrandUser: true,
    };
  }

  // Apollo doesn't know how to cache this query ...and telling it how is more complex than just doing it ourselves.
  const cachedContext = cache.get(user);
  if (cachedContext) {
    return cachedContext;
  }

  // Prevent the cache from growing indefinitely, we only care about the most recent token.
  cache.clear();

  const { accounts, brands, error } = await userAccountsQuery(apiClient);
  if (error) {
    throw json({ error: 'Failed to fetch client accounts for user' }, 500);
  }

  const defaultAccount = await getDefaultAccount(apiClient, accounts);

  const context = {
    isMultiAccountUser: accounts.length > 1,
    isMultiBrandUser: brands.length > 1,
    defaultAccount,
    defaultBrand: brands.length === 1 ? brands[0] : undefined,
  };

  cache.set(user, context);
  return context;
}

const isClient = (input: unknown): input is Client =>
  typeof input === 'object' && !!input && 'type' in input;

const isClientAccount = (input: unknown): input is ClientGroup =>
  isClient(input) && input.type === ClientType.EnterpriseClient;

const isClientBrand = (input: unknown): input is ClientBrand =>
  isClient(input) && input.type === ClientType.EnterpriseBrandClient;

export type UserClientsQueryResult = {
  accounts: ClientAccount[];
  brands: ClientBrand[];
  error: unknown | null;
};

async function userAccountsQuery(
  apiClient: ApolloClient<unknown>,
): Promise<UserClientsQueryResult> {
  try {
    const { data, error } = await apiClient.query({
      query: UserAccountsDocument,
    });
    if (error) {
      throw error;
    }

    const accounts = (
      data?.user?.accounts.nodes.filter(isClientAccount) ?? []
    ).map<ClientAccount>((client) => ({
      id: client.id,
      integrationKey: client.integrationKey,
      accountType: client.accountType,
      name: client.name,
      type: client.type,
    }));

    const brands = (
      data?.user?.brands.nodes.filter(isClientBrand) ?? []
    ).map<ClientBrand>((brand) => ({
      id: brand.id,
      integrationKey: brand.integrationKey,
      name: brand.name,
      type: brand.type,
    }));

    return {
      accounts,
      brands,
      error: null,
    };
  } catch (error) {
    captureException(error);
    return { accounts: [], brands: [], error };
  }
}

async function getDefaultAccount(
  apiClient: ApolloClient<unknown>,
  clientAccounts?: ClientAccount[],
): Promise<DefaultClientAccount | undefined> {
  if (!clientAccounts || clientAccounts.length !== 1) {
    return undefined;
  }

  const clientAccount = clientAccounts[0];
  const { data, error } = await apiClient.query({
    query: ClientAccountDocument,
    variables: {
      key: clientAccount.integrationKey,
    },
  });
  if (error) {
    throw error;
  }

  return {
    ...clientAccount,
    isPlatformAiEnabled: data?.clientAccount?.enablePlatformAI ?? false,
  };
}

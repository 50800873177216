/* eslint-disable @typescript-eslint/ban-ts-comment */

// Module Federation 💩

export function singleton<T>(key: string, value: T): T {
  const store = typeof window !== 'undefined' ? window : global;

  // @ts-ignore
  store[key] ??= value;
  // @ts-ignore
  return store[key];
}

import { useCallback } from 'react';
import { User } from '@shuttlerock/auth-types';
import { AuthClient, useUser as useAppUser } from '../internal/AuthClient';

type UseUserReturn = {
  user?: User;
  refresh: () => Promise<string>;
  /** @deprecated - Always returns `false` as the router now guarantees loading is complete before anything is rendered */
  isLoading: boolean;
};

export const useUser = (): UseUserReturn => {
  const user = useAppUser();

  const refresh = useCallback((): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      // Workaround to force refreshing user data
      // see https://github.com/auth0/auth0-react/issues/375
      setTimeout(() => {
        AuthClient.getAccessTokenSilently({ cacheMode: 'off' })
          .then(resolve)
          .catch(reject);
      });
    });
  }, []);

  return {
    user,
    refresh,
    isLoading: false,
  };
};
